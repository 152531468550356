import {
  carFuelCardAndId,
  carHeight,
  carIdAndWeight,
  carIdentifier,
  carImei,
  carManufacturerVinTransportLicence,
  carModel,
  carNumber,
  carTyresHoistPlatformDetails,
  dateNotRequired,
  licencePlate,
} from '../../validation';
import { Field, FieldName, State } from './car.state';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateField = (fieldName: FieldName, fields: State['fields'], state: State): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'id':
    case 'unladen_weight':
    case 'gross_weight': {
      validationResult = getValidationResult(carIdAndWeight, field);
      break;
    }
    case 'licence_plate': {
      const invalidCarLicencePlates = state.cars
        .map((car) => car.licence_plate)
        .filter((licencePlate) => licencePlate !== state.originalCar?.licence_plate);
      validationResult = getValidationResult(licencePlate.disallow(...invalidCarLicencePlates).insensitive(), field);
      break;
    }
    case 'car_number': {
      validationResult = getValidationResult(carNumber, field);
      break;
    }
    case 'fuel_id':
    case 'fuel_card': {
      validationResult = getValidationResult(carFuelCardAndId, field);
      break;
    }
    case 'car_identifier': {
      validationResult = getValidationResult(carIdentifier, field);
      break;
    }
    case 'manufacturer':
    case 'vin_number':
    case 'transport_licence': {
      validationResult = getValidationResult(carManufacturerVinTransportLicence, field);
      break;
    }
    case 'model': {
      validationResult = getValidationResult(carModel, field);
      break;
    }
    case 'imei': {
      validationResult = getValidationResult(carImei, field);
      break;
    }
    case 'tyres':
    case 'hoist':
    case 'platform_details': {
      validationResult = getValidationResult(carTyresHoistPlatformDetails, field);
      break;
    }
    case 'height': {
      validationResult = getValidationResult(carHeight, field);
      break;
    }
    case 'first_registration_date':
    case 'vehicle_inspection_date':
    case 'transport_licence_date':
    case 'hoist_inspection_date': {
      validationResult = getValidationResult(dateNotRequired, field);
      break;
    }
  }
  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (state: State): { fields: State['fields'] } => {
  const newFields = { ...state.fields } as any;
  for (const fieldName of Object.keys(state.fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, state.fields, state),
    };
  }
  return {
    fields: newFields,
  };
};
