import { DateTime } from 'luxon';
import { dateTimeFormat, LEGACY_ZERO_DATE_TIME } from '../../../formatters';
import { Action, PartialShipmentAdditionalService, State } from '../types/shipment.types';
import { validateFields } from '../shipmentValidation';
import { getDiff } from 'json-difference';
import { canAccessCoordination, canAccessCustomerCoordination } from '../../../utils';
import {
  AdditionalService,
  Office,
  Organization,
  OrganizationShipment,
  PricingModelWithAreas,
  PricingModelWithAreasPricingUnitEnum,
  Shipment,
  ShipmentRow,
  ShipmentStateEnum,
} from '../../../../../backend/src/common/api';
import { orderBy } from 'lodash';
import { getInitialState } from './shipment.state.utils';
import { AdditionalServiceField } from '../types/shipment.field.types';

function isShipment(value: Partial<Shipment> | Partial<OrganizationShipment>): value is Shipment {
  return value.hasOwnProperty('price');
}

const areAllFieldsValid = (state: Pick<State, 'fields' | 'additionalServiceFields'>) => {
  return (
    !Object.values(state.fields).some((field) => field.hasError) &&
    !state.additionalServiceFields.some((field) => field.hasError) &&
    areTimelineEventsInCorrectOrder(state.fields)
  );
};
export const createAdditionalServiceFields = (
  organizationAdditionalServices: AdditionalService[],
  shipmentAdditionalServices: PartialShipmentAdditionalService[],
): AdditionalServiceField[] => {
  const additionalServiceFields: AdditionalServiceField[] = [];
  orderBy(
    organizationAdditionalServices.map((orgAddService) => {
      const shipmentAdditionalService = shipmentAdditionalServices.find(
        (shipAddService) => shipAddService.additional_service_id === orgAddService.id,
      );
      return {
        additionalService: orgAddService,
        shipmentAdditionalService: shipmentAdditionalService,
      };
    }),
    'name',
  ).forEach((services) => {
    additionalServiceFields.push({
      required: false,
      hasError: false,
      feedback: undefined,
      value: services.shipmentAdditionalService?.quantity ?? 0,
      additionalService: services.additionalService,
      shipmentAdditionalServiceId: services.shipmentAdditionalService?.id,
    });
  });
  return additionalServiceFields;
};
const getShipmentPricingModelId = (
  shipmentPricingModelId: Shipment['pricing_model'] | undefined,
  shipmentBillingOfficeOrganizationSpecificId: Shipment['billing_office_organization_specific_id'] | undefined,
  organizationDefaultPricingModel: Organization['default_pricing_model'],
  organizationOffices: Office[],
): PricingModelWithAreas['id'] | null => {
  const shipmentBillingOfficeDefaultPricingModelId = organizationOffices.find(
    (office) => office.organization_specific_office_id === shipmentBillingOfficeOrganizationSpecificId,
  )?.default_pricing_model;
  const correctShipmentPricingModelId =
    shipmentPricingModelId ?? shipmentBillingOfficeDefaultPricingModelId ?? organizationDefaultPricingModel;
  return correctShipmentPricingModelId;
};

const getDateOrNullIfInvalidOrLegacy = (date: Date | null | undefined) => {
  const formattedDate = date ? DateTime.fromJSDate(date) : null;
  if (!date || !formattedDate?.isValid || formattedDate.toFormat(dateTimeFormat) === LEGACY_ZERO_DATE_TIME) {
    return null;
  }
  return date;
};
export const areTimelineEventsInCorrectOrder = (fields: State['fields']): any => {
  const billedAt = fields.billed_at.value ? DateTime.fromJSDate(fields.billed_at.value).endOf('day').toJSDate() : 0;
  const dates = [
    fields.ordered_at.value ?? 0,
    fields.arrived_to_pickup_location_at.value ?? 0,
    fields.picked_up_at.value ?? 0,
    fields.arrived_to_delivery_location_at.value ?? 0,
    fields.delivered_at.value ?? 0,
    billedAt,
  ];
  return dates.filter(Boolean).every((e, i, a) => !i || a[i - 1] <= e);
};
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_ROWS': {
      return {
        ...state,
        rows: action.payload.rows,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      const editedFields = state.originalFields && getDiff(validatedState.fields, state.originalFields).edited;
      const newErrors = editedFields
        ?.filter((x) => x[0].includes('hasError') || x[0].includes('feedback'))
        .some((x) => x[1]);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
        canSave:
          (newErrors || state.originalShipment?.billed_at) && !canAccessCoordination(state.currentUser) ? false : true,
      };
    }

    case 'CLEAR_SHIPMENT': {
      return {
        ...state,
        originalShipment: undefined,
        fields: getInitialState(action.payload.currentUser).fields,
        rows: [],
      };
    }
    case 'INITIALIZE_SHIPMENT': {
      const currentUser = action.payload.currentUser;
      const fields = {
        ...state.fields,
      };
      const deliveryWindowStartsAt =
        action.payload.shipment.agreed_delivery_window_starts_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.agreed_delivery_window_starts_at)
          : null;
      const deliveryWindowEndsAt =
        action.payload.shipment.agreed_delivery_window_ends_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.agreed_delivery_window_ends_at)
          : null;
      fields.delivery_date.value = deliveryWindowStartsAt !== null ? deliveryWindowStartsAt.toJSDate() : null;
      fields.delivery_time_window_start.value =
        deliveryWindowStartsAt !== null ? deliveryWindowStartsAt.toJSDate() : null;
      fields.delivery_time_window_end.value = deliveryWindowEndsAt !== null ? deliveryWindowEndsAt.toJSDate() : null;
      const pickupWindowStartsAt =
        action.payload.shipment.pickup_window_starts_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.pickup_window_starts_at)
          : null;
      const pickupWindowEndsAt =
        action.payload.shipment.pickup_window_ends_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.pickup_window_ends_at)
          : null;
      fields.pickup_date.value = pickupWindowStartsAt !== null ? pickupWindowStartsAt.toJSDate() : null;
      fields.pickup_time.value = // Pickup "window" is always an exact moment of time
        pickupWindowStartsAt !== null &&
        pickupWindowEndsAt !== null &&
        pickupWindowStartsAt.toMillis() === pickupWindowEndsAt.toMillis()
          ? pickupWindowStartsAt.toJSDate()
          : null;
      fields.ordered_at.value = getDateOrNullIfInvalidOrLegacy(action.payload.shipment.ordered_at);
      fields.arrived_to_pickup_location_at.value = getDateOrNullIfInvalidOrLegacy(
        action.payload.shipment.arrived_to_pickup_location_at,
      );
      fields.picked_up_at.value = getDateOrNullIfInvalidOrLegacy(action.payload.shipment.picked_up_at);
      fields.arrived_to_delivery_location_at.value = getDateOrNullIfInvalidOrLegacy(
        action.payload.shipment.arrived_to_delivery_location_at,
      );
      fields.delivered_at.value = getDateOrNullIfInvalidOrLegacy(action.payload.shipment.delivered_at);
      fields.billed_at.value = action.payload.shipment.billed_at ?? null;
      fields.organization_id.value = action.payload.shipment.organization_id ?? '';
      fields.delivery_office_organization_specific_id.value =
        action.payload.shipment.delivery_office_organization_specific_id ?? '';
      fields.delivery_name.value = action.payload.shipment.delivery_name ?? '';
      fields.delivery_address.value = action.payload.shipment.delivery_address ?? '';
      fields.delivery_city.value = action.payload.shipment.delivery_city ?? '';
      fields.delivery_phone_number.value = action.payload.shipment.delivery_phone_number ?? '';
      fields.delivery_postal_code.value = action.payload.shipment.delivery_postal_code ?? '';
      fields.job_number.value = action.payload.shipment.job_number ?? '';
      fields.note.value = action.payload.shipment.note ?? '';
      fields.billing_office_organization_specific_id.value =
        action.payload.shipment.billing_office_organization_specific_id || '';
      fields.orderer.value = action.payload.shipment.orderer ?? '';
      fields.orderer_phone_number.value = action.payload.shipment.orderer_phone_number ?? '';
      fields.reference_number.value = action.payload.shipment.reference_number ?? '';
      fields.pickup_office_organization_specific_id.value =
        action.payload.shipment.pickup_office_organization_specific_id ?? '';
      fields.pickup_name.value = action.payload.shipment.pickup_name ?? '';
      fields.pickup_address.value = action.payload.shipment.pickup_address ?? '';
      fields.pickup_city.value = action.payload.shipment.pickup_city ?? '';
      fields.pickup_phone_number.value = action.payload.shipment.pickup_phone_number ?? '';
      fields.pickup_phone_number_secondary.value = action.payload.shipment.pickup_phone_number_secondary ?? '';
      fields.pickup_postal_code.value = action.payload.shipment.pickup_postal_code ?? '';
      fields.length_ldm.value = action.payload.shipment.length_ldm ?? '';
      fields.weight_kg.value = action.payload.shipment.weight_kg ?? '';
      fields.chargeable_weight_kg.value = action.payload.shipment.chargeable_weight_kg ?? 0;
      fields.state.value = action.payload.shipment.state ?? ShipmentStateEnum.Noudettavissa;
      fields.volume_m3.value = action.payload.shipment.volume_m3 ?? '';
      fields.customer_distribution_area.value = action.payload.shipment.customer_distribution_area ?? '';
      fields.other_contract_number.value = action.payload.shipment.other_contract_number ?? '';
      fields.is_adr_delivery.value = action.payload.shipment.is_adr_delivery ?? false;
      fields.delivery_phone_number_secondary.value = action.payload.shipment.delivery_phone_number_secondary ?? '';
      fields.pricing_model.value = action.payload.shipment.pricing_model ?? '';
      fields.custom_pricing_category_id.value = action.payload.shipment.custom_pricing_category_id ?? '';
      fields.requires_combination_vehicle.value = action.payload.shipment.requires_combination_vehicle ?? false;
      fields.requires_hoist.value = action.payload.shipment.requires_hoist ?? false;
      fields.is_express_delivery.value = action.payload.shipment.is_express_delivery ?? false;
      fields.billing_reference_number.value = action.payload.shipment.billing_reference_number ?? '';
      if (isShipment(action.payload.shipment)) {
        fields.price.value = action.payload.shipment.price ?? 0;
        fields.legacy_price_basis.value = action.payload.shipment.legacy_price_basis ?? '';
        fields.has_contract_price.value = action.payload.shipment.has_contract_price ?? false;
        fields.load_id.value = action.payload.shipment.load_id ?? '';
        fields.hourly_work_hours.value = action.payload.shipment.hourly_work_hours ?? 0;
        fields.hourly_work_reason.value = action.payload.shipment.hourly_work_reason ?? '';
        fields.wait_reason.value = action.payload.shipment.wait_reason ?? '';
        fields.wait_hours.value = action.payload.shipment.wait_hours ?? 0;
        fields.has_additional_hourly_pricing.value = action.payload.shipment.has_additional_hourly_pricing ?? false;
        fields.legacy_etaisyys_field.value =
          action.payload.shipment.legacy_etaisyys_field !== null &&
          action.payload.shipment.legacy_etaisyys_field !== undefined
            ? action.payload.shipment.legacy_etaisyys_field
            : '';
        fields.delivery_status_url_identifier.value = action.payload.shipment.delivery_status_url_identifier ?? '';
        fields.has_distance_been_fixed.value = action.payload.shipment.has_distance_been_fixed ?? false;
      }
      const additionalServiceFields = createAdditionalServiceFields(
        action.payload.organizationAdditionalServices,
        action.payload.shipmentAdditionalServices,
      );
      const shipmentPricingModelId = getShipmentPricingModelId(
        action.payload.shipment.pricing_model,
        action.payload.shipment.billing_office_organization_specific_id,
        action.payload.organization.default_pricing_model,
        action.payload.organizationOffices,
      );
      fields.pricing_model.value = shipmentPricingModelId ?? '';
      const shipmentPricingModelDefaultCustomPricingCategoryId = action.payload.pricingModels.find(
        (pricingModel) => pricingModel.id === shipmentPricingModelId,
      )?.default_custom_pricing_category;
      const shipmentCustomPricingCategoryId =
        action.payload.shipment.custom_pricing_category_id ?? shipmentPricingModelDefaultCustomPricingCategoryId ?? '';
      fields.custom_pricing_category_id.value = shipmentCustomPricingCategoryId;
      const shipmentInEditableState = [
        ShipmentStateEnum.EiVarastossa,
        ShipmentStateEnum.Noudettavissa,
        ShipmentStateEnum.Odottaa,
      ].includes(fields.state.value);
      const canEditBasic =
        (!action.payload.shipment.load_id && !action.payload.shipment.billed_at && shipmentInEditableState) ||
        canAccessCoordination(currentUser);

      return {
        ...state,
        fields,
        originalFields: validateFields({ ...state, fields: fields }).fields,
        rows: action.payload.shipmentRows as ShipmentRow[],
        photos: action.payload.shipmentPhotos ?? [],
        originalShipment: action.payload.shipment as Shipment,
        load: action.payload.load,
        driver: action.payload.driver,
        car: action.payload.car,
        isValid: false,
        organizationOffices: action.payload.organizationOffices,
        organizationAdditionalServices: action.payload.organizationAdditionalServices ?? [],
        shipmentAdditionalServices: action.payload.shipmentAdditionalServices ?? [],
        updated_at: action.payload.shipment.updated_at ?? null,
        updated_by: action.payload.shipment.updated_by ?? '',
        canEdit: canEditBasic,
        canEditSomeFields: canEditBasic || (canAccessCustomerCoordination(currentUser) && shipmentInEditableState),
        canSave: !action.payload.shipment.billed_at || canAccessCoordination(currentUser),
        organizations: action.payload.organizations ?? [],
        additionalServiceFields,
        pricingModels: action.payload.pricingModels,
        currentUser: currentUser,
      };
    }
    case 'SET_RELATED_SHIPMENTS': {
      return {
        ...state,
        relatedShipments: action.payload.relatedShipments ?? [],
      };
    }
    case 'SET_IS_RELATED_SHIPMENTS_LOADING': {
      return {
        ...state,
        isRelatedShipmentsLoading: action.payload.isRelatedShipmentsLoading,
      };
    }
    case 'SET_IS_PHOTO_DIALOG_OPEN': {
      return {
        ...state,
        isAddPhotoDialogOpen: action.payload.isDialogOpen,
      };
    }
    case 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY': {
      const organizationDefaultPricingModelId = state.organizations.find(
        (organization) => organization.id === state.fields.organization_id.value,
      )?.default_pricing_model;
      const organizationOfficeDefaultPricingModelId = state.organizationOffices.find(
        (office) =>
          office.organization_specific_office_id === state.fields.billing_office_organization_specific_id.value,
      )?.default_pricing_model;
      let newPricingModel: number | '' | null = null;
      if (
        organizationOfficeDefaultPricingModelId &&
        organizationOfficeDefaultPricingModelId !== organizationDefaultPricingModelId
      ) {
        newPricingModel = organizationOfficeDefaultPricingModelId;
      } else {
        newPricingModel = organizationDefaultPricingModelId ?? state.fields.pricing_model.value ?? null;
      }

      let newCustomPricingCategoryId: number | '' | null = null;
      const shipmentPricingModel = state.pricingModels.find((pricingModel) => pricingModel.id === newPricingModel);
      if (shipmentPricingModel?.default_custom_pricing_category) {
        newCustomPricingCategoryId = shipmentPricingModel?.default_custom_pricing_category;
      } else if (shipmentPricingModel?.pricing_unit !== PricingModelWithAreasPricingUnitEnum.CustomCategory) {
        newCustomPricingCategoryId = state.fields.custom_pricing_category_id.value ?? null;
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          pricing_model: { ...state.fields.pricing_model, value: newPricingModel },
          custom_pricing_category_id: { ...state.fields.pricing_model, value: newCustomPricingCategoryId },
        },
      };
    }
    case 'SET_SHIPMENT_PHOTOS': {
      return {
        ...state,
        photos: action.payload.photos,
      };
    }
    case 'CHANGE_ORGANIZATION': {
      return {
        ...state,
        organizationOffices: action.payload.organizationOffices,
        organizationAdditionalServices: action.payload.organizationAdditionalServices,
        additionalServiceFields: createAdditionalServiceFields(
          action.payload.organizationAdditionalServices,
          state.shipmentAdditionalServices,
        ),
      };
    }
    case 'UPDATE_SHIPMENT_ADDITIONAL_SERVICE': {
      let quantity: number | '' = action.payload.value !== '' ? Number(action.payload.value) : '';
      quantity = Number.isNaN(quantity) ? '' : quantity;
      const additionalServiceFields = [...state.additionalServiceFields];
      const existingShipmentAdditionalServiceIndex = additionalServiceFields.findIndex(
        (shipAddServiceField) => shipAddServiceField.additionalService.id === action.payload.additionalService.id,
      );
      if (existingShipmentAdditionalServiceIndex === -1) {
        additionalServiceFields.push({
          additionalService: action.payload.additionalService,
          value: quantity || 0,
          required: false,
          hasError: false,
          feedback: undefined,
        });
      } else {
        additionalServiceFields[existingShipmentAdditionalServiceIndex].value = quantity;
      }
      return {
        ...state,
        additionalServiceFields,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};
