import { Grid2, Link, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api, Shipment } from '../../api';
import { orderBy } from 'lodash';
import { DeliveryWindow } from '../DeliveryWindow';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination, getShipmentStateChip } from '../../utils';
import { Link as RouterLink } from 'react-router-dom';
import { StarterItemForSubRow, SubRowContainer, subRowDefaults } from './MRTShipmentRowSubRow';
import { MRT_Row, MRT_RowData } from 'material-react-table';
import { ShipmentOrOrganizationShipment } from '../../views/shipments/types/shipment.types';

interface ShipmentSubRowsProps {
  shipments: ShipmentOrOrganizationShipment[];
}

export const ShipmentSubRows: React.FC<ShipmentSubRowsProps> = ({ shipments }) => {
  const small = 0.5;
  const medium = 1.25;
  const large = 2.25;
  const extraLarge = 2.75;

  if (shipments.length === 0) {
    return (
      <SubRowContainer>
        <Grid2 {...subRowDefaults} sx={{ width: '90vw' }}>
          <StarterItemForSubRow size={small} />
          <Grid2>ei toimituksia</Grid2>
        </Grid2>
      </SubRowContainer>
    );
  } else {
    return (
      <SubRowContainer>
        {orderBy(shipments, ['delivered_at', 'order_in_load', 'id'], ['asc', 'asc', 'desc']).map((shipment, index) => {
          return (
            <Grid2 {...subRowDefaults} key={index} data-cy="shipment-row-grid">
              <StarterItemForSubRow size={small} />
              <Grid2 size={{ xs: small }}>
                <Link component={RouterLink} to={{ pathname: `/shipments/${shipment.id}` }}>
                  {shipment.id ?? ''}
                </Link>
              </Grid2>
              <Grid2 size={{ xs: medium }}>{shipment.reference_number ?? ''}</Grid2>
              <Grid2 size={{ xs: large }}>{`${shipment.pickup_address}, ${shipment.pickup_city}`}</Grid2>
              <Grid2 size={{ xs: large }}>{`${shipment.delivery_address}, ${shipment.delivery_city}`}</Grid2>
              <Grid2 size={{ xs: medium }}>
                <DeliveryWindow
                  startsAt={shipment.agreed_delivery_window_starts_at}
                  endsAt={shipment.agreed_delivery_window_ends_at}
                  onlyTime={true}
                />
              </Grid2>
              <Grid2 size={{ xs: medium }}>{getShipmentStateChip(shipment as Shipment)}</Grid2>
              <Tooltip title={shipment.note}>
                <Grid2
                  size={{ xs: extraLarge }}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {shipment.note ?? ''}
                </Grid2>
              </Tooltip>
            </Grid2>
          );
        })}
      </SubRowContainer>
    );
  }
};

interface SubRowAsyncProps<TData extends MRT_RowData> {
  row: MRT_Row<TData>;
}

export const ShipmentSubRowAsync = <TData extends MRT_RowData>({ row }: SubRowAsyncProps<TData>) => {
  const [shipments, setShipments] = useState<ShipmentOrOrganizationShipment[]>([]);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);

  const load = async () => {
    const shipments = hasCoordinationAccess
      ? (
          await api.loads.getLoadShipments({
            loadId: Number(row.original.id),
          })
        ).data
      : (
          await api.organizationLoads.getOrganizationLoadShipments({
            loadId: Number(row.original.id),
            organizationId: currentUser?.organization_id ?? '',
          })
        ).data;
    setShipments(shipments);
  };

  useEffect(() => {
    load();
  }, [row.original.id]);

  return <ShipmentSubRows shipments={shipments} />;
};
