import { Assignment, Driver, DriverWithWorkHoursAndTimeOffs, CarrierLoad, Organization } from '../../api';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { DateRange, getDefaultDateRange, SetDateRangeAction } from '../../utils/dateRangeUtils';
import { ShipmentOrOrganizationShipmentWithOrganizationName } from '../shipments/types/shipment.types';

export type JobShipment = ShipmentOrOrganizationShipmentWithOrganizationName & {
  driverName?: string;
  sumOrganizationWorkHours?: number;
  sumDriverDailyWorkHours?: number;
  sumDailyWorkHours?: number;
  pricePerHourOrganization?: number;
};

export interface State {
  shipments: JobShipment[];
  organizations: Organization[];
  assignments: Assignment[];
  loads: CarrierLoad[];
  drivers: Driver[];
  driversWithWorkHours: DriverWithWorkHoursAndTimeOffs[];
  notification: NotificationType;
  dateRange: DateRange;
  isLoading: boolean;
  selectedOrganization: Organization | null;
  selectedDriver: Driver | null;
}

export const getInitialState = (): State => {
  return {
    shipments: [],
    organizations: [],
    assignments: [],
    loads: [],
    drivers: [],
    driversWithWorkHours: [],
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    selectedOrganization: null,
    selectedDriver: null,
  };
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: {
        shipments: JobShipment[];
        organizations: Organization[];
        assignments: Assignment[];
        loads: CarrierLoad[];
        drivers: Driver[];
        driversWithWorkHours: DriverWithWorkHoursAndTimeOffs[];
      };
    }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | { type: 'SET_SELECTED_ORGANIZATION'; payload: Organization | null }
  | { type: 'SET_SELECTED_DRIVER'; payload: Driver | null };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        shipments: action.payload.shipments.map((shipment) => {
          const organization = action.payload.organizations.find((org) => org.id === shipment.organization_id);
          const organizationAssignmentIds = action.payload.assignments
            .filter((assignment) => assignment.organization_id === organization?.id)
            .map((a) => a.assignment_specific_id);
          const load = action.payload.loads.find((l) => l.id === shipment.load_id);
          const driver = load ? action.payload.drivers.find((d) => d.id === load.driver_id) : undefined;
          const dailyWorkHours = action.payload.driversWithWorkHours
            .flatMap((d) => d.work_hours)
            .filter(
              (w) =>
                w.date &&
                load?.drive_date &&
                DateTime.fromJSDate(w.date).startOf('day').equals(DateTime.fromJSDate(load.drive_date).startOf('day')),
            );
          const organizationWorkHours = action.payload.driversWithWorkHours
            .flatMap((d) => d.work_hours)
            .filter((w) => organizationAssignmentIds.includes(w.assignment_id));
          const sumOrganizationWorkHours = sumBy(organizationWorkHours, 'net_hours');
          const sumDailyWorkHours = sumBy(dailyWorkHours, 'net_hours');
          const sumDriverDailyWorkHours = sumBy(
            dailyWorkHours.filter(
              (w) =>
                organizationAssignmentIds.includes(w.assignment_id) && w.employee_number === driver?.employee_number,
            ),
            'net_hours',
          );
          return {
            ...shipment,
            organizationName: organization?.name,
            driverName: driver ? `${driver.last_name} ${driver.first_name}` : '',
            sumOrganizationWorkHours: sumOrganizationWorkHours,
            sumDriverDailyWorkHours: sumDriverDailyWorkHours,
            sumDailyWorkHours: sumDailyWorkHours,
          };
        }),
        organizations: action.payload.organizations,
        assignments: action.payload.assignments,
        loads: action.payload.loads,
        drivers: action.payload.drivers,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_SELECTED_ORGANIZATION':
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case 'SET_SELECTED_DRIVER':
      return {
        ...state,
        selectedDriver: action.payload,
      };
  }
};
