/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CarrierCarService,
  CarrierCarServiceFromJSON,
  CarrierCarServiceFromJSONTyped,
  CarrierCarServiceToJSON,
} from './CarrierCarService';

/**
 *
 * @export
 * @interface InlineResponse2011
 */
export interface InlineResponse2011 {
  /**
   *
   * @type {CarrierCarService}
   * @memberof InlineResponse2011
   */
  data: CarrierCarService;
}

export function InlineResponse2011FromJSON(json: any): InlineResponse2011 {
  return InlineResponse2011FromJSONTyped(json, false);
}

export function InlineResponse2011FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2011 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CarrierCarServiceFromJSON(json['data']),
  };
}

export function InlineResponse2011ToJSON(value?: InlineResponse2011 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CarrierCarServiceToJSON(value.data),
  };
}
