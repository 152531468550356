import { DateTime } from 'luxon';
import { ShipmentOrOrganizationShipment } from '../types/shipment.types';
import { ShipmentStateEnum } from '../../../../../backend/src/common/api';

const getPreviousDateOrCurrentIfPassed = (date: Date | null) => {
  const previousDate = date ? DateTime.fromJSDate(date) : null;
  const currentDate = DateTime.local();
  if (date === null || (previousDate && previousDate.startOf('day') < currentDate.startOf('day'))) {
    return DateTime.local()
      .set({
        hour: previousDate?.hour ?? 0,
        minute: previousDate?.minute ?? 0,
        second: previousDate?.second ?? 0,
      })
      .toJSDate();
  } else {
    return previousDate ? previousDate.toJSDate() : null;
  }
};

export const copyShipment = <T extends ShipmentOrOrganizationShipment>(shipment: T): T => {
  return {
    ...shipment,
    state: ShipmentStateEnum.Noudettavissa,
    orderer: null,
    orderer_phone_number: null,
    recipient: null,
    has_distance_been_fixed: false,
    billed_at: null,
    pickup_window_starts_at: shipment.pickup_window_starts_at
      ? getPreviousDateOrCurrentIfPassed(shipment.pickup_window_starts_at)
      : null,
    pickup_window_ends_at: shipment.pickup_window_ends_at
      ? getPreviousDateOrCurrentIfPassed(shipment.pickup_window_ends_at)
      : null,
    agreed_delivery_window_starts_at: getPreviousDateOrCurrentIfPassed(shipment.agreed_delivery_window_starts_at),
    agreed_delivery_window_ends_at: getPreviousDateOrCurrentIfPassed(shipment.agreed_delivery_window_ends_at),
  };
};
export const convertToReturnShipment = <T extends ShipmentOrOrganizationShipment>(shipment: T): T => {
  return {
    ...copyShipment(shipment),
    pickup_address: shipment.delivery_address,
    pickup_city: shipment.delivery_city,
    pickup_postal_code: shipment.delivery_postal_code,
    pickup_name: shipment.delivery_name,
    pickup_phone_number: shipment.delivery_phone_number,
    pickup_phone_number_secondary: shipment.delivery_phone_number_secondary,
    delivery_address: shipment.pickup_address,
    delivery_city: shipment.pickup_city,
    delivery_postal_code: shipment.pickup_postal_code,
    delivery_name: shipment.pickup_name,
    delivery_phone_number: shipment.pickup_phone_number,
    delivery_phone_number_secondary: shipment.pickup_phone_number_secondary,
  };
};
