import React from 'react';
import { AddressLink } from '../../../components/map';
import { createMRTColumnHelper } from 'material-react-table';
import { dateFormatShort } from '../../../formatters';
import { DateTime } from 'luxon';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Shipment } from '../../../api';
import { sortShipmentByStateAndTimestamp, getShipmentStateChip } from '../../../utils';
import { ShipmentOrOrganizationShipmentWithOrganizationName } from '../types/shipment.types';

interface ShipmentColumnProps {
  shipmentSetId?: string;
  loadLink?: boolean;
}

export const getShipmentColumns = ({ shipmentSetId, loadLink }: ShipmentColumnProps) => {
  const columnHelper = createMRTColumnHelper<ShipmentOrOrganizationShipmentWithOrganizationName>();

  return [
    columnHelper.accessor('id', {
      header: 'Id',
      Cell: ({ cell, renderedCellValue }) => (
        <Link
          component={RouterLink}
          to={{
            pathname: `/shipments/${cell.getValue()}`,
            ...(shipmentSetId && { search: `?shipmentSet=${shipmentSetId}` }),
          }}
        >
          {renderedCellValue}
        </Link>
      ),
      filterFn: 'includesString',
    }),
    columnHelper.accessor('reference_number', {
      header: 'Asiakasviite',
    }),
    columnHelper.accessor('organizationName', {
      header: 'Asiakas',
      filterVariant: 'multi-select',
      filterFn: 'arrIncludesSome',
    }),
    columnHelper.accessor('load_id', {
      header: 'Kuorma',
      Cell: ({ cell, renderedCellValue }) =>
        loadLink ? (
          <Link component={RouterLink} to={{ pathname: `/loads/${cell.getValue()}` }}>
            {renderedCellValue}
          </Link>
        ) : (
          renderedCellValue
        ),
    }),
    columnHelper.accessor('pickup_name', {
      header: 'Nimi',
    }),
    columnHelper.accessor('pickup_address', {
      header: 'Osoite',
      Cell: ({ cell }) => (
        <AddressLink
          className="pickup-address-link"
          title={cell.row.original.pickup_address ?? ''}
          address={cell.row.original.pickup_address}
          postalCode={cell.row.original.pickup_postal_code}
          city={cell.row.original.pickup_city}
        />
      ),
    }),
    columnHelper.accessor('pickup_city', {
      header: 'Kaupunki',
    }),
    columnHelper.accessor('delivery_name', {
      header: 'Nimi',
    }),
    columnHelper.accessor('delivery_address', {
      header: 'Osoite',
      Cell: ({ cell }) => (
        <AddressLink
          className="delivery-address-link"
          title={cell.row.original.delivery_address ?? ''}
          address={cell.row.original.delivery_address}
          postalCode={cell.row.original.delivery_postal_code}
          city={cell.row.original.delivery_city}
        />
      ),
    }),
    columnHelper.accessor('delivery_city', {
      header: 'Kaupunki',
    }),
    columnHelper.accessor('state', {
      header: 'Tila',
      filterVariant: 'multi-select',
      filterFn: 'arrIncludesSome',
      enableEditing: false,
      sortingFn: (rowA, rowB) => sortShipmentByStateAndTimestamp(rowA.original as Shipment, rowB.original as Shipment),
      Cell: ({ cell }) => getShipmentStateChip(cell.row.original as Shipment),
    }),
    columnHelper.accessor(
      (row) =>
        row.agreed_delivery_window_starts_at
          ? DateTime.fromJSDate(row.agreed_delivery_window_starts_at)
          : row.agreed_delivery_window_starts_at,
      {
        id: 'agreed_delivery_window_starts_at',
        header: 'Toimituspäivä',
        filterFn: 'dateFilterFn',
        filterVariant: 'date',
        Cell: ({ cell }) => cell.getValue<DateTime>().toFormat(dateFormatShort),
      },
    ),
    columnHelper.accessor('delivery_phone_number', {
      id: 'delivery_phone_number',
      header: 'Puhelinnumero',
    }),
    columnHelper.accessor('note', {
      header: 'Lisätietoja',
    }),
  ];
};
