import React, { BaseSyntheticEvent, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import { updateFieldValue } from '../shipment.state';
import { RouteLink } from '../../../components/map';
import { FieldName } from '../types/shipment.field.types';
import { Shipment, User } from '../../../../../backend/src/common/api';
import { canAccessCustomerPricing, canAccessPricing, isHostOrganizationUser } from '../../../utils';
import { formatDistancePricingBasis, formatFloat, formatPrice, formatPricingUnit } from '../../../formatters';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { PricingModelPicker } from '../../../components/PricingModelPicker';
import { DispatchableInputProps, StyledFormControl, WithUser } from './inputs';

interface IsEditShipment {
  isEditShipment: boolean;
}

const HasDistanceBeenFixedCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="has_distance_been_fixed"
          name="has_distance_been_fixed"
          checked={state.fields.has_distance_been_fixed.value || false}
          onChange={(event) => {
            updateFieldValue(event.target.name as FieldName, !state.fields.has_distance_been_fixed.value, dispatch);
            if (state.fields.has_distance_been_fixed.value === false) {
              updateFieldValue('legacy_etaisyys_field', state.originalShipment?.legacy_etaisyys_field ?? '', dispatch);
            }
          }}
        />
      }
      label="Korjattu etäisyys"
    />
  );
};

const LegacyEtaisyysField: React.FC<DispatchableInputProps & IsEditShipment> = ({
  state,
  dispatch,
  disabled,
  isEditShipment,
}) => {
  return (
    <>
      <PriceContainer>
        <FormControl className={'legacy_etaisyys_field'} error={state.fields.legacy_etaisyys_field.hasError}>
          <InputLabel htmlFor="legacy_etaisyys_field">Etäisyys</InputLabel>
          <Input
            disabled={!state.fields.has_distance_been_fixed.value || disabled}
            id="legacy_etaisyys_field"
            name="legacy_etaisyys_field"
            type="number"
            startAdornment={<InputAdornment position="start">km</InputAdornment>}
            value={state.fields.legacy_etaisyys_field.value}
            onChange={(event) => {
              updateFieldValue(
                'legacy_etaisyys_field',
                event.target.value !== '' ? parseFloat(event.target.value) : '',
                dispatch,
              );
            }}
            inputProps={{ min: 0, max: 2000 }}
          />
          {state.fields.legacy_etaisyys_field.feedback ? (
            <FormHelperText>{state.fields.legacy_etaisyys_field.feedback}</FormHelperText>
          ) : null}
        </FormControl>
        {isEditShipment ? <HasDistanceBeenFixedCheckbox state={state} dispatch={dispatch} /> : null}
        {isEditShipment ? (
          <RouteLink
            address1={state.fields.pickup_address.value}
            postalCode1={state.fields.pickup_postal_code.value}
            city1={state.fields.pickup_city.value}
            address2={state.fields.delivery_address.value}
            postalCode2={state.fields.delivery_postal_code.value}
            city2={state.fields.delivery_city.value}
          />
        ) : null}
      </PriceContainer>
    </>
  );
};
const PriceContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
});
const ContractPriceCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="has_contract_price_checkbox"
          name="has_contract_price"
          checked={state.fields.has_contract_price.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.has_contract_price.value, dispatch)
          }
        />
      }
      label="Sopimushinta"
    />
  );
};
const Price: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <PriceContainer>
      <StyledFormControl error={state.fields.price.hasError}>
        <InputLabel htmlFor="price">Hinta</InputLabel>
        <Input
          disabled={!state.fields.has_contract_price.value}
          id="price"
          name="price"
          type="number"
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          value={state.fields.price.value}
          inputProps={{ min: 0 }}
          //disable changing value with mouse scroll
          onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
          onChange={(event) =>
            updateFieldValue('price', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
          }
        />
        {state.fields.price.feedback ? <FormHelperText>{state.fields.price.feedback}</FormHelperText> : null}
      </StyledFormControl>
      <ContractPriceCheckbox state={state} dispatch={dispatch} />
    </PriceContainer>
  );
};
const HourlyWorkAndWaitContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
});
const HourlyWorkAndWaitStandardFormControl = styled(FormControl)({
  flexGrow: 0,
  width: '5rem',
});
const HourlyWorkAndWaitTextField = styled(TextField)({
  flexGrow: 1,
});
const HourlyWorkHours: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitStandardFormControl error={state.fields.hourly_work_hours.hasError}>
      <InputLabel htmlFor="hourly_work_hours">Tuntityö</InputLabel>
      <Input
        disabled={disabled}
        id="hourly_work_hours"
        name="hourly_work_hours"
        type="number"
        startAdornment={<InputAdornment position="start">h</InputAdornment>}
        value={state.fields.hourly_work_hours.value}
        inputProps={{ min: 0 }}
        //disable changing value with mouse scroll
        onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
        onChange={(event) =>
          updateFieldValue(
            'hourly_work_hours',
            event.target.value !== '' ? parseFloat(event.target.value) : '',
            dispatch,
          )
        }
      />
      {state.fields.hourly_work_hours.feedback ? (
        <FormHelperText>{state.fields.hourly_work_hours.feedback}</FormHelperText>
      ) : null}
    </HourlyWorkAndWaitStandardFormControl>
  );
};
const HourlyWorkReason: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitTextField
      disabled={disabled}
      name="hourly_work_reason"
      error={state.fields.hourly_work_reason.hasError}
      helperText={state.fields.hourly_work_reason.feedback}
      label="Tuntityön syy"
      value={state.fields.hourly_work_reason.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};
const WaitHours: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitStandardFormControl error={state.fields.wait_hours.hasError}>
      <InputLabel htmlFor="wait_hours">Odotusaika</InputLabel>
      <Input
        disabled={disabled}
        id="wait_hours"
        name="wait_hours"
        type="number"
        startAdornment={<InputAdornment position="start">h</InputAdornment>}
        value={state.fields.wait_hours.value}
        inputProps={{ min: 0 }}
        onChange={(event) =>
          updateFieldValue('wait_hours', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.wait_hours.feedback ? <FormHelperText>{state.fields.wait_hours.feedback}</FormHelperText> : null}
    </HourlyWorkAndWaitStandardFormControl>
  );
};
const WaitReason: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitTextField
      disabled={disabled}
      name="wait_reason"
      error={state.fields.wait_reason.hasError}
      helperText={state.fields.wait_reason.feedback}
      label="Odotuksen syy"
      value={state.fields.wait_reason.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};
const getNewPriceBasis = (currentUser: User | undefined, originalShipment: Shipment | undefined) => {
  const PriceBasisContainer = styled('div')({
    color: 'rgba(0, 0, 0, 0.6)',
    paddingBottom: '0.5rem',
    fontSize: '0.9rem',
  });

  const PriceBasisList = styled('ul')({
    marginTop: 0,
    paddingLeft: '1rem',
  });

  const newPriceBasisObject = originalShipment?.price_basis;
  if (!newPriceBasisObject) {
    return;
  }

  if (newPriceBasisObject.error_message) {
    return (
      <PriceBasisContainer>
        <span>Hinnoitteluvirhe</span>
        <PriceBasisList>
          <li>{newPriceBasisObject.error_message}</li>
        </PriceBasisList>
      </PriceBasisContainer>
    );
  }

  return (
    <PriceBasisContainer>
      {/* host organization users have price and legacy_etaisyys_field input field, no need to show these twice */}
      {canAccessCustomerPricing(currentUser) && !isHostOrganizationUser(currentUser) && (
        <>
          <span>Hinta</span>
          <PriceBasisList>
            <li>{originalShipment.price} €</li>
          </PriceBasisList>
          <span>Etäisyys</span>
          <PriceBasisList>
            <li>{originalShipment.legacy_etaisyys_field} km</li>
          </PriceBasisList>
        </>
      )}
      <span>Hintaperuste</span>
      <PriceBasisList>
        {newPriceBasisObject.basic_price ? <li>Perushinta: {formatPrice(newPriceBasisObject.basic_price)} €</li> : null}
        {newPriceBasisObject.pricing_unit_value && newPriceBasisObject.distance_value ? (
          <li>
            Peruste: {newPriceBasisObject.pricing_unit_value} {formatPricingUnit(newPriceBasisObject.pricing_unit)} /{' '}
            {newPriceBasisObject.distance_value}{' '}
            {formatDistancePricingBasis(newPriceBasisObject.distance_pricing_basis)}
          </li>
        ) : null}
        {newPriceBasisObject.express_delivery ? (
          <li>Pikalisä: {formatPrice(newPriceBasisObject.express_delivery)} €</li>
        ) : null}
        {newPriceBasisObject.price_ratio_for_combined_shipment &&
        newPriceBasisObject.price_ratio_for_combined_shipment !== 1 ? (
          <li>
            Osuus yhteistoimituksesta: {formatFloat(newPriceBasisObject.price_ratio_for_combined_shipment * 100)} %
          </li>
        ) : null}
        {newPriceBasisObject.working_hours || newPriceBasisObject.price_per_hour ? (
          <li>
            Työtunnit: {newPriceBasisObject.working_hours} h *{' '}
            {newPriceBasisObject.price_per_hour
              ? formatPrice(newPriceBasisObject.price_per_hour)
              : newPriceBasisObject.price_per_hour}{' '}
            €
          </li>
        ) : null}
        {newPriceBasisObject.combination_vehicle_price_per_hour ? (
          <li>
            Yhdistelmäajoneuvolisä: {newPriceBasisObject.working_hours} h *{' '}
            {formatPrice(newPriceBasisObject.combination_vehicle_price_per_hour)} €
          </li>
        ) : null}
        {newPriceBasisObject.car_type ? <li>Autotyyppi: {newPriceBasisObject.car_type}</li> : null}
        {newPriceBasisObject.additional_services?.length && newPriceBasisObject.additional_services?.length > 0 ? (
          <li>
            Lisäpalvelut:
            <ul style={{ paddingLeft: '0.5rem' }}>
              {newPriceBasisObject?.additional_services?.map((additionalService, index) => (
                <li key={index} style={{ listStyle: 'none' }}>
                  {additionalService.name} /{' '}
                  {additionalService.unit_price
                    ? formatPrice(additionalService.unit_price)
                    : additionalService.unit_price}{' '}
                  € * {additionalService.amount} kpl
                </li>
              ))}
            </ul>
          </li>
        ) : null}
      </PriceBasisList>
    </PriceBasisContainer>
  );
};
const AdditionalHourlyPricingCheckbox: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <Tooltip title="Tuntityön hinta lisätään erikseen muun hinnan päälle">
      <FormControlLabel
        control={
          <Checkbox
            name="has_additional_hourly_pricing"
            className="has_additional_hourly_pricing_checkbox"
            checked={state.fields.has_additional_hourly_pricing.value || false}
            disabled={disabled}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.has_additional_hourly_pricing.value,
                dispatch,
              )
            }
          />
        }
        label="Tuntilisä"
      />
    </Tooltip>
  );
};

export const PricingFieldSet: React.FC<DispatchableInputProps & WithUser & IsEditShipment> = ({
  dispatch,
  state,
  currentUser,
  isEditShipment,
}) => {
  const hasContractPrice = Boolean(state.fields.has_contract_price.value);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <FieldSet id="pricing-fields-container">
      <legend>Hinnoittelu</legend>
      {canAccessPricing(currentUser) && (
        <>
          <PricingModelPicker
            disabled={state.isLoading || hasContractPrice}
            pricingModels={state.pricingModels}
            label={'Hinnoittelumalli'}
            value={
              state.pricingModels.find((pricingModel) => pricingModel.id === state.fields.pricing_model.value) ?? null
            }
            onChange={(pricingModel) => {
              updateFieldValue('pricing_model', pricingModel?.id ?? null, dispatch);
              if (pricingModel?.custom_pricing_categories && pricingModel?.default_custom_pricing_category) {
                updateFieldValue('custom_pricing_category_id', pricingModel?.default_custom_pricing_category, dispatch);
              } else {
                updateFieldValue('custom_pricing_category_id', '', dispatch);
              }
            }}
            isTooltipVisible={isTooltipVisible}
            tooltip="Ei käytössä sopimushinnoittelun kanssa"
            onTooltipOpen={() => {
              if (hasContractPrice) {
                setIsTooltipVisible(true);
              }
            }}
            onTooltipClose={() => setIsTooltipVisible(false)}
          />
          <Price state={state} dispatch={dispatch} />
        </>
      )}
      {canAccessPricing(currentUser) || canAccessCustomerPricing(currentUser)
        ? getNewPriceBasis(currentUser, state.originalShipment)
        : null}
      {canAccessPricing(currentUser) && (
        <>
          <HourlyWorkAndWaitContainer>
            <HourlyWorkHours state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <HourlyWorkReason state={state} dispatch={dispatch} disabled={!state.canEdit} />{' '}
            <AdditionalHourlyPricingCheckbox state={state} dispatch={dispatch} disabled={!state.canEdit} />
          </HourlyWorkAndWaitContainer>
          <HourlyWorkAndWaitContainer>
            <WaitHours state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <WaitReason state={state} dispatch={dispatch} disabled={!state.canEdit} />
          </HourlyWorkAndWaitContainer>
          <LegacyEtaisyysField
            state={state}
            dispatch={dispatch}
            disabled={!state.canEdit}
            isEditShipment={isEditShipment}
          />
        </>
      )}
    </FieldSet>
  );
};
