import React from 'react';
import { DatePickerProps, DesktopDatePicker, PickersActionBarAction } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { dateFormat } from '../../formatters';

const convertToUTCNoonOrNull = (date: DateTime): Date | null => {
  return date ? date.set({ hour: 12, second: 0, millisecond: 0 }).setZone('utc').toJSDate() : null;
};

export const convertToUTCNoon = (date: DateTime): Date => {
  return date.set({ hour: 12, second: 0, millisecond: 0 }).setZone('utc').toJSDate();
};

export const todayNoon = convertToUTCNoon(DateTime.now());

export const mobileActionBar = (props: any) => ({
  actions: props.wrapperVariant === 'mobile' ? (['clear', 'accept'] as PickersActionBarAction[]) : [],
});

export const StandardDatePicker: React.FC<DatePickerProps<any>> = (props) => {
  return (
    <DesktopDatePicker
      {...props}
      format={dateFormat}
      value={props.value instanceof Date ? DateTime.fromJSDate(props.value) : props.value}
      slotProps={{
        actionBar: mobileActionBar,
        textField: {
          ...props.slotProps?.textField,
          size: 'small',
          variant: (props.slotProps?.textField as any).variant ?? 'standard',
          error: (props.slotProps?.textField as any).error,
          helperText: (props.slotProps?.textField as any).helperText,
          inputProps: {
            placeholder: '',
          },
        },
      }}
      onChange={(date, context) => {
        const formattedDate = convertToUTCNoonOrNull(date);
        props.onChange && props.onChange(formattedDate, context);
      }}
    />
  );
};
