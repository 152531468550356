/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * User object that is returned from the API
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  readonly organization_id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  gsm: string | null;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_admin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_coordinator?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_multi_organization?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_superuser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_workshop?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_driver?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_manager?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_organization_superuser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  can_access_customer_report?: boolean;
  /**
   *
   * @type {number}
   * @memberof User
   */
  driver_id?: number | null;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json['username'],
    organization_id: json['organization_id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: json['email'],
    gsm: json['gsm'],
    is_admin: !exists(json, 'is_admin') ? undefined : json['is_admin'],
    is_coordinator: !exists(json, 'is_coordinator') ? undefined : json['is_coordinator'],
    is_multi_organization: !exists(json, 'is_multi_organization') ? undefined : json['is_multi_organization'],
    is_superuser: !exists(json, 'is_superuser') ? undefined : json['is_superuser'],
    is_workshop: !exists(json, 'is_workshop') ? undefined : json['is_workshop'],
    is_driver: !exists(json, 'is_driver') ? undefined : json['is_driver'],
    is_manager: !exists(json, 'is_manager') ? undefined : json['is_manager'],
    is_organization_superuser: !exists(json, 'is_organization_superuser')
      ? undefined
      : json['is_organization_superuser'],
    can_access_customer_report: !exists(json, 'can_access_customer_report')
      ? undefined
      : json['can_access_customer_report'],
    driver_id: !exists(json, 'driver_id') ? undefined : json['driver_id'],
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    gsm: value.gsm,
    is_admin: value.is_admin,
    is_coordinator: value.is_coordinator,
    is_multi_organization: value.is_multi_organization,
    is_superuser: value.is_superuser,
    is_workshop: value.is_workshop,
    is_driver: value.is_driver,
    is_manager: value.is_manager,
    is_organization_superuser: value.is_organization_superuser,
    can_access_customer_report: value.can_access_customer_report,
    driver_id: value.driver_id,
  };
}
