import React, { FunctionComponent, useEffect, useMemo, useReducer } from 'react';
import { api, getAllPages } from '../../api';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { MRT_DefaultDisplayColumn, MRT_TableOptions, createMRTColumnHelper } from 'material-react-table';
import MRT from '../../components/MaterialReactTable/MRT';
import {
  Action,
  DriverWithWorkDaysAndSummary,
  getInitialState,
  reducer,
  State,
  workHourDateFormat,
} from './workHourMonitoring.state';
import { DateTime, Interval } from 'luxon';
import { formatProfession } from '../../formatters';
import theme from '../../theme';
import { Box, Chip } from '@mui/material';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { isWeekend } from '../../utils';

const VIEW_ID = 'workHourMonitoring' as const;

const load = async (state: State, dispatch: React.Dispatch<Action>, abortController: AbortController) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [driverWithWorkHoursAndTimeOffs] = await Promise.all([
      getAllPages(
        api.drivers.getDriversWithWorkHoursAndTimeOffs.bind(api.drivers),
        {
          agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
          agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
        },
        abortController,
      ),
    ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        driverWithWorkHoursAndTimeOffs,
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa tuntiseurantaa',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

const getBackgroundColor = (props: any, date: DateTime) => {
  const value = props.row.original[date.toFormat('yyyyMMdd')];
  if (value && value.timeOffsOrWorkHoursOverlap) {
    return 'red';
  }
  const timeOffType = value ? value.timeOffType : 0;
  switch (timeOffType) {
    case 1:
      return theme.palette.holiday;
    case 2:
      return theme.palette.dayOff;
    case 3:
      return theme.palette.sickLeave;
    default:
      return isWeekend(date) ? theme.palette.table.main : undefined;
  }
};

const WorkHourMonitoring: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser?.organization_id) {
      const abortController = new AbortController();
      load(state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [currentUser, state.dateRange]);

  const disabledColumnProperties: Partial<typeof MRT_DefaultDisplayColumn> = {
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnOrdering: false,
  };

  const columnHelper = createMRTColumnHelper<DriverWithWorkDaysAndSummary>();

  const dayColumns =
    state.dateRange?.start && state.dateRange?.end
      ? Interval.fromDateTimes(state.dateRange.start, state.dateRange.end)
          .splitBy({ day: 1 })
          .map((d) => {
            const value: any = d.start.toFormat(workHourDateFormat);
            return columnHelper.accessor(
              (row) =>
                row[value] && row[value].workHourAndTimeOffNetHoursSum ? row[value].workHourAndTimeOffNetHoursSum : '',
              {
                id: d.start.toFormat(workHourDateFormat),
                header: d.start.toFormat('dd.MM'),
                size: 60,
                muiTableHeadCellProps: {
                  style: {
                    backgroundColor: isWeekend(d.start) ? theme.palette.table.main : undefined,
                  },
                },
                muiTableBodyCellProps(props) {
                  return {
                    align: 'right',
                    style: {
                      backgroundColor: getBackgroundColor(props, d.start),
                    },
                  };
                },
                enableColumnFilter: false,
                enableSorting: false,
                enableResizing: false,
                ...disabledColumnProperties,
              },
            );
          })
      : [];

  const columns = useMemo(
    () => [
      columnHelper.accessor('driver', {
        header: 'Kuljettaja',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        ...disabledColumnProperties,
      }),
      columnHelper.accessor((row) => (row.profession ? formatProfession(row.profession) : row.profession), {
        id: 'profession',
        header: 'Ammatti',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        ...disabledColumnProperties,
      }),
      ...dayColumns,
      columnHelper.accessor((row) => (row.sum !== '0 / 0' ? row.sum : row.sum), {
        id: 'sum',
        header: 'Yhteensä',
        muiTableBodyCellProps: {
          align: 'right',
          style: {
            fontWeight: 'bold',
          },
        },
        enableColumnFilter: false,
        ...disabledColumnProperties,
      }),
      columnHelper.accessor((row) => (row.overUnder ? row.overUnder : row.overUnder), {
        id: 'overUnder',
        header: 'Yli/Ali',
        muiTableBodyCellProps(props) {
          return {
            align: 'right',
            style: {
              fontWeight: 'bold',
              backgroundColor:
                props.row.original.overUnder > 0
                  ? theme.palette.error.main
                  : props.row.original.overUnder < 0
                    ? theme.palette.warning.main
                    : undefined,
            },
          };
        },
        enableColumnFilter: false,
        sortingFn: 'alphanumeric',
        ...disabledColumnProperties,
      }),
    ],
    [dayColumns],
  );

  const data = React.useMemo(() => state.driverAndDates, [state.driverAndDates]);

  const tableOptions: MRT_TableOptions<DriverWithWorkDaysAndSummary> = {
    data,
    columns,
    onColumnOrderChange: () => undefined,
    initialState: {
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 500 },
      sorting: [
        { id: 'overUnder', desc: true },
        { id: 'driver', desc: false },
      ],
    },
  };

  return (
    <>
      <MRT
        isLoading={state.isLoading}
        viewId={VIEW_ID}
        customActions={() => (
          <Box>
            <Chip sx={{ background: theme.palette.holiday }} label="Loma" />
            <Chip sx={{ background: theme.palette.dayOff }} label="Pekkanen" />
            <Chip sx={{ background: theme.palette.sickLeave }} label="Sairas" />
            <Chip sx={{ background: 'red' }} label="Päällekkäin" />
          </Box>
        )}
        header={'Tuntiseuranta'}
        dispatch={dispatch}
        salaryPeriodDateRange={true}
        initialDateRange={state.dateRange}
        {...tableOptions}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default WorkHourMonitoring;
