import { Dispatch, Reducer } from 'redux';
import { Organization, api, User } from '../api';

export type CarrierUser = User & { carrier_id: number };

const SET_CURRENT_USER = 'SET_CURRENT_USER' as const;
const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN' as const;
const SET_ORGANIZATION = 'SET_ORGANIZATION' as const;

interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  user: User | CarrierUser;
}

interface SetIsLoggedInAction {
  type: typeof SET_IS_LOGGED_IN;
  isLoggedIn: boolean;
}

interface SetOrganizationAction {
  type: typeof SET_ORGANIZATION;
  organization: Organization;
}

type AuthAction = SetCurrentUserAction | SetIsLoggedInAction | SetOrganizationAction;

interface State {
  user?: User | CarrierUser;
  organization?: Organization;
  isLoggedIn: boolean;
}

const initialState: State = {
  isLoggedIn: false,
};

const authReducer: Reducer<State, AuthAction> = (state = initialState, action): State => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, user: action.user };
    case SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.isLoggedIn };
    case SET_ORGANIZATION:
      return { ...state, organization: action.organization };
    default:
      return state;
  }
};

export const initializeAuthentication = () => {
  return async (dispatch: Dispatch<AuthAction>): Promise<void> => {
    try {
      const currentUser = await api.auth.getCurrentUser();
      const organization = (await api.organizations.getOrganization({ organizationId: currentUser.organization_id }))
        .data;
      dispatch({
        type: SET_CURRENT_USER,
        user: { ...currentUser, carrier_id: organization.carrier_id },
      });
      dispatch({
        type: SET_ORGANIZATION,
        organization: organization,
      });
      dispatch({
        type: SET_IS_LOGGED_IN,
        isLoggedIn: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export default authReducer;
