/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CarrierCarPostBody,
  CarrierCarPostBodyFromJSON,
  CarrierCarPostBodyToJSON,
  CarrierCarPutBody,
  CarrierCarPutBodyFromJSON,
  CarrierCarPutBodyToJSON,
  CarrierCarServicePostBody,
  CarrierCarServicePostBodyFromJSON,
  CarrierCarServicePostBodyToJSON,
  CarrierCarServicePutBody,
  CarrierCarServicePutBodyFromJSON,
  CarrierCarServicePutBodyToJSON,
  CarrierLoadPostBody,
  CarrierLoadPostBodyFromJSON,
  CarrierLoadPostBodyToJSON,
  CarrierLoadPutBody,
  CarrierLoadPutBodyFromJSON,
  CarrierLoadPutBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  InlineResponse2001,
  InlineResponse2001FromJSON,
  InlineResponse2001ToJSON,
  InlineResponse2002,
  InlineResponse2002FromJSON,
  InlineResponse2002ToJSON,
  InlineResponse2003,
  InlineResponse2003FromJSON,
  InlineResponse2003ToJSON,
  InlineResponse2004,
  InlineResponse2004FromJSON,
  InlineResponse2004ToJSON,
  InlineResponse2005,
  InlineResponse2005FromJSON,
  InlineResponse2005ToJSON,
  InlineResponse201,
  InlineResponse201FromJSON,
  InlineResponse201ToJSON,
  InlineResponse2011,
  InlineResponse2011FromJSON,
  InlineResponse2011ToJSON,
  InlineResponse2012,
  InlineResponse2012FromJSON,
  InlineResponse2012ToJSON,
} from '../models';

export interface CarriersApiDeleteCarrierCarRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarByLicencePlateRequest {
  carrierId: number;
  carLicencePlate: string;
}

export interface CarriersApiGetCarrierCarCarServicesRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarServicesRequest {
  carrierId: number;
}

export interface CarriersApiGetCarrierCarsRequest {
  carrierId: number;
}

export interface CarriersApiGetCarrierLoadRequest {
  carrierId: number;
  loadId: number;
}

export interface CarriersApiGetCarrierLoadsRequest {
  carrierId: number;
  next?: number;
  driveDateRangeStartsAt?: Date;
  driveDateRangeEndsAt?: Date;
}

export interface CarriersApiGetCarrierShipmentRequest {
  carrierId: number;
  shipmentId: number;
}

export interface CarriersApiGetCarrierShipmentsRequest {
  carrierId: number;
  next?: number;
  coordinationDateRangeStartsAt?: Date;
  coordinationDateRangeEndsAt?: Date;
}

export interface CarriersApiGetCarrierUnhandledShipmentRowsRequest {
  carrierId: number;
  coordinationDateRangeStartsAt?: Date;
  coordinationDateRangeEndsAt?: Date;
}

export interface CarriersApiGetCarrireCarServiceRequest {
  carrierId: number;
  carId: number;
  carServiceId: number;
}

export interface CarriersApiPostCarrierCarRequest {
  carrierId: number;
  carrierCarPostBody: CarrierCarPostBody;
}

export interface CarriersApiPostCarrierCarServiceRequest {
  carrierId: number;
  carId: number;
  carrierCarServicePostBody: CarrierCarServicePostBody;
}

export interface CarriersApiPostCarrierLoadRequest {
  carrierId: number;
  carrierLoadPostBody: CarrierLoadPostBody;
}

export interface CarriersApiPutCarrierCarRequest {
  carrierId: number;
  carId: number;
  carrierCarPutBody: CarrierCarPutBody;
}

export interface CarriersApiPutCarrierCarServiceRequest {
  carrierId: number;
  carId: number;
  carServiceId: number;
  carrierCarServicePutBody: CarrierCarServicePutBody;
}

export interface CarriersApiPutCarrierLoadRequest {
  carrierId: number;
  loadId: number;
  carrierLoadPutBody: CarrierLoadPutBody;
}

/**
 *
 */
export class CarriersApi extends runtime.BaseAPI {
  /**
   */
  async deleteCarrierCarRaw(
    requestParameters: CarriersApiDeleteCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling deleteCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling deleteCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteCarrierCar(
    requestParameters: CarriersApiDeleteCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteCarrierCarRaw(requestParameters, initOverrides);
  }

  /**
   */
  async getCarrierCarRaw(
    requestParameters: CarriersApiGetCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCar(
    requestParameters: CarriersApiGetCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.getCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarByLicencePlateRaw(
    requestParameters: CarriersApiGetCarrierCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarByLicencePlate.',
      );
    }

    if (requestParameters.carLicencePlate === null || requestParameters.carLicencePlate === undefined) {
      throw new runtime.RequiredError(
        'carLicencePlate',
        'Required parameter requestParameters.carLicencePlate was null or undefined when calling getCarrierCarByLicencePlate.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/by_licence_plate/{car_licence_plate}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_licence_plate'}}`, encodeURIComponent(String(requestParameters.carLicencePlate))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarByLicencePlate(
    requestParameters: CarriersApiGetCarrierCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.getCarrierCarByLicencePlateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarCarServicesRaw(
    requestParameters: CarriersApiGetCarrierCarCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarCarServices.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrierCarCarServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarCarServices(
    requestParameters: CarriersApiGetCarrierCarCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse200> {
    const response = await this.getCarrierCarCarServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarServicesRaw(
    requestParameters: CarriersApiGetCarrierCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/car_services`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarServices(
    requestParameters: CarriersApiGetCarrierCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse200> {
    const response = await this.getCarrierCarServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarsRaw(
    requestParameters: CarriersApiGetCarrierCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2001>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCars.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCars(
    requestParameters: CarriersApiGetCarrierCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2001> {
    const response = await this.getCarrierCarsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadRaw(
    requestParameters: CarriersApiGetCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2012>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2012FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoad(
    requestParameters: CarriersApiGetCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2012> {
    const response = await this.getCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadsRaw(
    requestParameters: CarriersApiGetCarrierLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2002>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoads.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDateRangeStartsAt !== undefined) {
      queryParameters['drive_date_range_starts_at'] = (requestParameters.driveDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.driveDateRangeEndsAt !== undefined) {
      queryParameters['drive_date_range_ends_at'] = (requestParameters.driveDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoads(
    requestParameters: CarriersApiGetCarrierLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2002> {
    const response = await this.getCarrierLoadsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierShipmentRaw(
    requestParameters: CarriersApiGetCarrierShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2005>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getCarrierShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments/{shipment_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierShipment(
    requestParameters: CarriersApiGetCarrierShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2005> {
    const response = await this.getCarrierShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierShipmentsRaw(
    requestParameters: CarriersApiGetCarrierShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2003>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.coordinationDateRangeStartsAt !== undefined) {
      queryParameters['coordination_date_range_starts_at'] = (requestParameters.coordinationDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.coordinationDateRangeEndsAt !== undefined) {
      queryParameters['coordination_date_range_ends_at'] = (requestParameters.coordinationDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierShipments(
    requestParameters: CarriersApiGetCarrierShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2003> {
    const response = await this.getCarrierShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierUnhandledShipmentRowsRaw(
    requestParameters: CarriersApiGetCarrierUnhandledShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2004>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierUnhandledShipmentRows.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.coordinationDateRangeStartsAt !== undefined) {
      queryParameters['coordination_date_range_starts_at'] = (requestParameters.coordinationDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.coordinationDateRangeEndsAt !== undefined) {
      queryParameters['coordination_date_range_ends_at'] = (requestParameters.coordinationDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments/unhandled_shipment_rows`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierUnhandledShipmentRows(
    requestParameters: CarriersApiGetCarrierUnhandledShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2004> {
    const response = await this.getCarrierUnhandledShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrireCarServiceRaw(
    requestParameters: CarriersApiGetCarrireCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrireCarService.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrireCarService.',
      );
    }

    if (requestParameters.carServiceId === null || requestParameters.carServiceId === undefined) {
      throw new runtime.RequiredError(
        'carServiceId',
        'Required parameter requestParameters.carServiceId was null or undefined when calling getCarrireCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services/{car_service_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId)))
          .replace(`{${'car_service_id'}}`, encodeURIComponent(String(requestParameters.carServiceId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async getCarrireCarService(
    requestParameters: CarriersApiGetCarrireCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.getCarrireCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierCarRaw(
    requestParameters: CarriersApiPostCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierCar.',
      );
    }

    if (requestParameters.carrierCarPostBody === null || requestParameters.carrierCarPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierCarPostBody',
        'Required parameter requestParameters.carrierCarPostBody was null or undefined when calling postCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarPostBodyToJSON(requestParameters.carrierCarPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierCar(
    requestParameters: CarriersApiPostCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.postCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierCarServiceRaw(
    requestParameters: CarriersApiPostCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierCarService.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling postCarrierCarService.',
      );
    }

    if (
      requestParameters.carrierCarServicePostBody === null ||
      requestParameters.carrierCarServicePostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'carrierCarServicePostBody',
        'Required parameter requestParameters.carrierCarServicePostBody was null or undefined when calling postCarrierCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarServicePostBodyToJSON(requestParameters.carrierCarServicePostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierCarService(
    requestParameters: CarriersApiPostCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.postCarrierCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierLoadRaw(
    requestParameters: CarriersApiPostCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2012>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierLoad.',
      );
    }

    if (requestParameters.carrierLoadPostBody === null || requestParameters.carrierLoadPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierLoadPostBody',
        'Required parameter requestParameters.carrierLoadPostBody was null or undefined when calling postCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierLoadPostBodyToJSON(requestParameters.carrierLoadPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2012FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierLoad(
    requestParameters: CarriersApiPostCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2012> {
    const response = await this.postCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierCarRaw(
    requestParameters: CarriersApiPutCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling putCarrierCar.',
      );
    }

    if (requestParameters.carrierCarPutBody === null || requestParameters.carrierCarPutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierCarPutBody',
        'Required parameter requestParameters.carrierCarPutBody was null or undefined when calling putCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarPutBodyToJSON(requestParameters.carrierCarPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierCar(
    requestParameters: CarriersApiPutCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.putCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierCarServiceRaw(
    requestParameters: CarriersApiPutCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierCarService.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling putCarrierCarService.',
      );
    }

    if (requestParameters.carServiceId === null || requestParameters.carServiceId === undefined) {
      throw new runtime.RequiredError(
        'carServiceId',
        'Required parameter requestParameters.carServiceId was null or undefined when calling putCarrierCarService.',
      );
    }

    if (
      requestParameters.carrierCarServicePutBody === null ||
      requestParameters.carrierCarServicePutBody === undefined
    ) {
      throw new runtime.RequiredError(
        'carrierCarServicePutBody',
        'Required parameter requestParameters.carrierCarServicePutBody was null or undefined when calling putCarrierCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services/{car_service_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId)))
          .replace(`{${'car_service_id'}}`, encodeURIComponent(String(requestParameters.carServiceId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarServicePutBodyToJSON(requestParameters.carrierCarServicePutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierCarService(
    requestParameters: CarriersApiPutCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.putCarrierCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierLoadRaw(
    requestParameters: CarriersApiPutCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2012>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling putCarrierLoad.',
      );
    }

    if (requestParameters.carrierLoadPutBody === null || requestParameters.carrierLoadPutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierLoadPutBody',
        'Required parameter requestParameters.carrierLoadPutBody was null or undefined when calling putCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierLoadPutBodyToJSON(requestParameters.carrierLoadPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2012FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierLoad(
    requestParameters: CarriersApiPutCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2012> {
    const response = await this.putCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
