import { Shipment, User } from '../../../api';
import { Box, FormHelperText, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { Dispatch } from 'react';
import { DateTime } from 'luxon';
import { dateFormat, dateTimeFormat } from '../../../formatters';
import { updateFieldValue } from '../shipment.state';
import { Action, State } from '../types/shipment.types';
import { Circle } from '@mui/icons-material';
import theme from '../../../theme';
import { StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { StandardDateTimePicker } from '../../../components/DateAndTimePickers/StandardDateTimePicker';
import { canAccessCoordination } from '../../../utils';
import { areTimelineEventsInCorrectOrder } from '../state/shipment.reducer';
import { Field, FieldName } from '../types/shipment.field.types';

interface ShipmentTimelineProps {
  state: State;
  disabled?: boolean;
  currentUser?: User;
  shipment: Shipment;
  dispatch: Dispatch<Action>;
}

interface DateAndTimeTimelineComponentProps {
  fieldName: FieldName;
  field: Field<any>;
  isCoordinator: boolean;
  dispatch: Dispatch<Action>;
  shipmentField: Date | null;
  title: string;
  dateAndTime: boolean;
  unloadedWithCustomerPermission?: boolean;
  recipient?: string | null;
}

const TimelineComponent: React.FC<DateAndTimeTimelineComponentProps> = ({
  fieldName,
  field,
  isCoordinator,
  dispatch,
  shipmentField,
  title,
  dateAndTime,
  recipient,
  unloadedWithCustomerPermission,
}) => {
  const Picker = dateAndTime ? StandardDateTimePicker : StandardDatePicker;
  const className = fieldName + '_field';
  if (!shipmentField && !isCoordinator) {
    return null;
  }
  return (
    <ListItem data-cy={`${fieldName}_timeline_item`} sx={{ height: '4rem' }}>
      <Box>
        {isCoordinator ? (
          <Picker
            value={field.value}
            slotProps={{
              textField: {
                id: fieldName,
                name: fieldName,
                error: field.hasError,
                helperText: field.feedback,
              },
            }}
            onChange={(date) => {
              updateFieldValue(fieldName, date, dispatch);
            }}
          />
        ) : (
          <ListItemText
            className={className}
            sx={{ width: '8rem' }}
            primary={
              shipmentField
                ? DateTime.fromJSDate(shipmentField).toFormat(dateAndTime ? dateTimeFormat : dateFormat)
                : null
            }
          />
        )}
      </Box>
      <ListItemIcon sx={{ justifyContent: 'center' }}>
        <Circle fontSize="small" />
      </ListItemIcon>
      <Box>
        <ListItemText
          className="recipient_field"
          primary={title}
          secondary={recipient && `${unloadedWithCustomerPermission ? 'PAL' : 'Vastaanottaja'}: ${recipient}`}
        />
      </Box>
    </ListItem>
  );
};

export const ShipmentTimeline: React.FC<ShipmentTimelineProps> = ({ shipment, state, dispatch, currentUser }) => {
  const isCoordinator = canAccessCoordination(currentUser);

  return (
    <>
      <List style={{ justifyContent: 'flex-start' }} className="shipment-timeline">
        <TimelineComponent
          fieldName="ordered_at"
          field={state.fields.ordered_at}
          isCoordinator={isCoordinator}
          dispatch={dispatch}
          shipmentField={shipment.ordered_at}
          title={'Tilattu'}
          dateAndTime={true}
        />
        <TimelineComponent
          fieldName="arrived_to_pickup_location_at"
          field={state.fields.arrived_to_pickup_location_at}
          isCoordinator={isCoordinator}
          dispatch={dispatch}
          shipmentField={shipment.arrived_to_pickup_location_at}
          title="Noutokohteessa"
          dateAndTime={true}
        />
        <TimelineComponent
          fieldName="picked_up_at"
          field={state.fields.picked_up_at}
          isCoordinator={isCoordinator}
          dispatch={dispatch}
          shipmentField={shipment.picked_up_at}
          title="Noudettu"
          dateAndTime={true}
        />
        <TimelineComponent
          fieldName="arrived_to_delivery_location_at"
          field={state.fields.arrived_to_delivery_location_at}
          isCoordinator={isCoordinator}
          dispatch={dispatch}
          shipmentField={shipment.arrived_to_delivery_location_at}
          title="Toimituskohteessa"
          dateAndTime={true}
        />
        <TimelineComponent
          fieldName="delivered_at"
          field={state.fields.delivered_at}
          isCoordinator={isCoordinator}
          dispatch={dispatch}
          shipmentField={shipment.delivered_at}
          title="Toimitettu"
          unloadedWithCustomerPermission={shipment.unloaded_with_customer_permission}
          recipient={shipment.recipient}
          dateAndTime={true}
        />
        <TimelineComponent
          fieldName="billed_at"
          field={state.fields.billed_at}
          isCoordinator={isCoordinator}
          dispatch={dispatch}
          shipmentField={shipment.billed_at}
          title="Laskutettu"
          dateAndTime={false}
        />
      </List>
      {isCoordinator && !areTimelineEventsInCorrectOrder(state.fields) ? (
        <FormHelperText id="timeline-event-errors" style={{ color: theme.palette.error.main }}>
          Toimitustapahtumien päivämäärien pitää olla oikeassa järjestyksessä.
        </FormHelperText>
      ) : null}
    </>
  );
};
