/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentStateEnum,
  ShipmentStateEnumFromJSON,
  ShipmentStateEnumFromJSONTyped,
  ShipmentStateEnumToJSON,
} from './ShipmentStateEnum';

/**
 *
 * @export
 * @interface SearchOrganizationShipmentsPostBody
 */
export interface SearchOrganizationShipmentsPostBody {
  /**
   *
   * @type {number}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  load_id?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  readonly organization_id?: string;
  /**
   *
   * @type {ShipmentStateEnum}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  state?: ShipmentStateEnum;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  reference_number?: string;
  /**
   *
   * @type {number}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  job_number?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  delivery_name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  delivery_address?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  delivery_city?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  delivery_phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  pickup_name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  pickup_address?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  pickup_city?: string;
  /**
   *
   * @type {Date}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  agreed_delivery_window_date_range_starts_at?: Date;
  /**
   *
   * @type {Date}
   * @memberof SearchOrganizationShipmentsPostBody
   */
  agreed_delivery_window_date_range_ends_at?: Date;
}

export function SearchOrganizationShipmentsPostBodyFromJSON(json: any): SearchOrganizationShipmentsPostBody {
  return SearchOrganizationShipmentsPostBodyFromJSONTyped(json, false);
}

export function SearchOrganizationShipmentsPostBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchOrganizationShipmentsPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    load_id: !exists(json, 'load_id') ? undefined : json['load_id'],
    organization_id: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    state: !exists(json, 'state') ? undefined : ShipmentStateEnumFromJSON(json['state']),
    reference_number: !exists(json, 'reference_number') ? undefined : json['reference_number'],
    id: !exists(json, 'id') ? undefined : json['id'],
    job_number: !exists(json, 'job_number') ? undefined : json['job_number'],
    delivery_name: !exists(json, 'delivery_name') ? undefined : json['delivery_name'],
    delivery_address: !exists(json, 'delivery_address') ? undefined : json['delivery_address'],
    delivery_city: !exists(json, 'delivery_city') ? undefined : json['delivery_city'],
    delivery_phone_number: !exists(json, 'delivery_phone_number') ? undefined : json['delivery_phone_number'],
    pickup_name: !exists(json, 'pickup_name') ? undefined : json['pickup_name'],
    pickup_address: !exists(json, 'pickup_address') ? undefined : json['pickup_address'],
    pickup_city: !exists(json, 'pickup_city') ? undefined : json['pickup_city'],
    agreed_delivery_window_date_range_starts_at: !exists(json, 'agreed_delivery_window_date_range_starts_at')
      ? undefined
      : new Date(json['agreed_delivery_window_date_range_starts_at']),
    agreed_delivery_window_date_range_ends_at: !exists(json, 'agreed_delivery_window_date_range_ends_at')
      ? undefined
      : new Date(json['agreed_delivery_window_date_range_ends_at']),
  };
}

export function SearchOrganizationShipmentsPostBodyToJSON(value?: SearchOrganizationShipmentsPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    load_id: value.load_id,
    state: ShipmentStateEnumToJSON(value.state),
    reference_number: value.reference_number,
    id: value.id,
    job_number: value.job_number,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_city: value.delivery_city,
    delivery_phone_number: value.delivery_phone_number,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    pickup_city: value.pickup_city,
    agreed_delivery_window_date_range_starts_at:
      value.agreed_delivery_window_date_range_starts_at === undefined
        ? undefined
        : value.agreed_delivery_window_date_range_starts_at.toISOString(),
    agreed_delivery_window_date_range_ends_at:
      value.agreed_delivery_window_date_range_ends_at === undefined
        ? undefined
        : value.agreed_delivery_window_date_range_ends_at.toISOString(),
  };
}
