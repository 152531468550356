import {
  AssignmentsApi,
  AuthenticationApi,
  AviApi,
  CarsApi,
  CarriersApi,
  CarServicesApi,
  Configuration,
  CoordinatorResourcesApi,
  DistancesApi,
  DriverGroupsApi,
  DriverResourcesApi,
  DriverShipmentsApi,
  EmployeeResourcesApi,
  KeysApi,
  LoadsApi,
  OfficesApi,
  OrganizationAdditionalServicesApi,
  OrganizationAssignmentsApi,
  OrganizationCarsApi,
  OrganizationDriversApi,
  OrganizationLoadsApi,
  OrganizationOfficesApi,
  OrganizationPricingApi,
  OrganizationsApi,
  OrganizationScheduleShipmentsApi,
  OrganizationShipmentsApi,
  OrganizationSummaryApi,
  OrganizationUsersApi,
  PricingApi,
  ReportsApi,
  ResponseContext,
  SearchApi,
  ShipmentsApi,
  SummaryApi,
  TimeOffsApi,
  UsersApi,
  WorkHoursApi,
  FutureWorkHourApi,
  ColumnLayoutsApi,
  CreateNewUserApi,
} from '../../backend/src/common/api';

export * from '../../backend/src/common/api';

const redirectToLoginMiddleware = async (responseContext: ResponseContext) => {
  if (
    responseContext.response.status === 401 &&
    window.location.pathname !== '/first_login' &&
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/create_user' &&
    !window.location.pathname.match(/avi\/.\S*/)
  ) {
    location.href = '/login';
    return;
  }
  return responseContext.response;
};

const config = new Configuration({
  basePath: location.origin,
  middleware: [{ post: redirectToLoginMiddleware }],
});

export const api = {
  auth: new AuthenticationApi(config),
  organizations: new OrganizationsApi(config),
  organizationShipments: new OrganizationShipmentsApi(config),
  organizationAdditionalServices: new OrganizationAdditionalServicesApi(config),
  organizationScheduleShipments: new OrganizationScheduleShipmentsApi(config),
  shipments: new ShipmentsApi(config),
  loads: new LoadsApi(config),
  users: new UsersApi(config),
  organizationUsers: new OrganizationUsersApi(config),
  drivers: new DriverResourcesApi(config),
  driverShipments: new DriverShipmentsApi(config),
  driverGroups: new DriverGroupsApi(config),
  employees: new EmployeeResourcesApi(config),
  cars: new CarsApi(config),
  carServices: new CarServicesApi(config),
  organizationOffices: new OrganizationOfficesApi(config),
  offices: new OfficesApi(config),
  search: new SearchApi(config),
  keys: new KeysApi(config),
  reports: new ReportsApi(config),
  organizationAssignments: new OrganizationAssignmentsApi(config),
  assignments: new AssignmentsApi(config),
  coordinationStatus: new CoordinatorResourcesApi(config),
  summary: new SummaryApi(config),
  organizationSummary: new OrganizationSummaryApi(config),
  pricing: new PricingApi(config),
  organizationPricing: new OrganizationPricingApi(config),
  organizationLoads: new OrganizationLoadsApi(config),
  organizationCars: new OrganizationCarsApi(config),
  organizationDrivers: new OrganizationDriversApi(config),
  distances: new DistancesApi(config),
  workHours: new WorkHoursApi(config),
  timeOffs: new TimeOffsApi(config),
  avi: new AviApi(config),
  futureWorkHour: new FutureWorkHourApi(config),
  columnLayouts: new ColumnLayoutsApi(config),
  CreateNewUser: new CreateNewUserApi(config),
};

export const apiV2 = {
  carrier: new CarriersApi(config),
};

interface ResponseBody {
  data: any[];
  next: number | string | null;
}

export const getAllPages = async <RequestParameters, ResponseType extends ResponseBody>(
  apiFunction: (requestParams: RequestParameters, initOverrides?: RequestInit) => Promise<ResponseType>,
  requestParameters: RequestParameters,
  abortController?: AbortController,
): Promise<ResponseType['data']> => {
  let resources: ResponseType['data'] = [];
  let next: ResponseType['next'] = null;
  do {
    if (abortController?.signal.aborted ?? false) {
      return [];
    }
    try {
      const response = await apiFunction(
        {
          ...requestParameters,
          next: next !== null ? next : undefined,
        },
        { signal: abortController?.signal },
      );

      next = response.next;
      resources = resources.concat(response.data);
    } catch (e) {
      if (e instanceof DOMException && e.name == 'AbortError') {
        // signal.abort called. Return empty result
        return [];
      }
      throw e;
    }
  } while (next !== null);
  return resources;
};

interface DataResponseBody {
  data: any | any[];
}

export const getData = async <RequestParameters, ResponseType extends DataResponseBody>(
  apiFunction: (requestParams: RequestParameters, initOverrides?: RequestInit) => Promise<ResponseType>,
  requestParameters: RequestParameters,
  abortController?: AbortController,
): Promise<ResponseType['data']> => {
  if (abortController?.signal.aborted ?? false) {
    return [];
  }
  try {
    const response = await apiFunction(requestParameters, { signal: abortController?.signal });
    return response.data;
  } catch (e) {
    if (e instanceof DOMException && e.name == 'AbortError') {
      // signal.abort called. Return empty result
      return [];
    }
    throw e;
  }
};
