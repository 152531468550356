import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { Dispatch } from 'react';
import { Organization, Shipment } from '../../api';
import { ShipmentRowWithIsNewAndUpdated } from './components/NewShipmentRowTable';
import { Action, State } from './types/shipment.types';
import { FieldName, FieldValue } from './types/shipment.field.types';

export const getAgreedDeliveryWindow = (fields: State['fields']): { startsAt: DateTime; endsAt: DateTime } => {
  const zeroTime = DateTime.fromFormat('00:00', 'HH:mm');
  const agreedDeliveryTimeWindowStartsAt =
    fields.delivery_time_window_start.value && DateTime.fromJSDate(fields.delivery_time_window_start.value).isValid
      ? DateTime.fromJSDate(fields.delivery_time_window_start.value)
      : zeroTime;
  const agreedDeliveryTimeWindowEndsAt =
    fields.delivery_time_window_end.value && DateTime.fromJSDate(fields.delivery_time_window_end.value).isValid
      ? DateTime.fromJSDate(fields.delivery_time_window_end.value)
      : zeroTime;
  const agreedDeliveryWindowStartsAt = DateTime.fromJSDate(fields.delivery_date.value as Date).set({
    hour: agreedDeliveryTimeWindowStartsAt.hour,
    minute: agreedDeliveryTimeWindowStartsAt.minute,
  });
  return {
    startsAt: agreedDeliveryWindowStartsAt,
    endsAt: agreedDeliveryWindowStartsAt.set({
      hour: agreedDeliveryTimeWindowEndsAt.hour,
      minute: agreedDeliveryTimeWindowEndsAt.minute,
    }),
  };
};

export const getPickupWindow = (fields: State['fields']): { startsAt: DateTime; endsAt: DateTime } | null => {
  const pickupTime = DateTime.fromJSDate(fields.pickup_time.value as Date);
  let pickupWindowStartsAt = (fields.pickup_date.value && DateTime.fromJSDate(fields.pickup_date.value)) ?? null;
  let pickupWindowEndsAt = (pickupWindowStartsAt && pickupWindowStartsAt.plus({ days: 1 })) ?? null;
  if (pickupWindowStartsAt === null || pickupWindowEndsAt === null) {
    return null;
  }
  if (pickupTime.isValid && pickupWindowStartsAt) {
    pickupWindowStartsAt = pickupWindowStartsAt.set({
      hour: pickupTime.hour,
      minute: pickupTime.minute,
    });
    pickupWindowEndsAt = pickupWindowStartsAt;
  }
  return {
    startsAt: pickupWindowStartsAt,
    endsAt: pickupWindowEndsAt,
  };
};

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export const getShipmentDefaultValues = (organization: Organization): Partial<Shipment> => {
  return {
    agreed_delivery_window_starts_at:
      organization.default_agreed_delivery_window_time_starts_at === null
        ? DateTime.local()
            .set({
              hour: 7,
              minute: 0,
              second: 0,
            })
            .toJSDate()
        : DateTime.fromFormat(organization.default_agreed_delivery_window_time_starts_at, 'HH:mm:ss').toJSDate(),
    agreed_delivery_window_ends_at:
      organization.default_agreed_delivery_window_time_ends_at === null
        ? DateTime.local()
            .set({
              hour: 16,
              minute: 0,
              second: 0,
            })
            .toJSDate()
        : DateTime.fromFormat(organization.default_agreed_delivery_window_time_ends_at, 'HH:mm:ss').toJSDate(),
    organization_id: organization.id,
  };
};

export function updateRows(rows: ShipmentRowWithIsNewAndUpdated[], dispatch: Dispatch<Action>): void {
  const rowsWithTotalWeight = rows.map((x) => ({
    ...x,
    total_weight_kg: (x.weight_per_piece_kg ?? 0) * (x.quantity ?? 0),
  }));
  dispatch({
    type: 'SET_ROWS',
    payload: {
      rows: rowsWithTotalWeight,
    },
  });
  updateFieldValue(
    'weight_kg',
    rowsWithTotalWeight.reduce((weight, row) => (weight += row.total_weight_kg ?? 0), 0),
    dispatch,
  );
  updateFieldValue(
    'volume_m3',
    rowsWithTotalWeight.reduce((volume, row) => (volume += row.volume_m3 ?? 0), 0),
    dispatch,
  );
}
