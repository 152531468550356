import React, { useEffect, useReducer, useState } from 'react';
import { Paper, styled, Tab, Tabs } from '@mui/material';
import { ShipmentReport } from './ShipmentReport';
import { ShipmentAggregatesReport } from './ShipmentAggregatesReport';
import Main from '../../components/Main';
import { getInitialState, reducer } from './reports.state';
import { ShipmentBillingReport } from './ShipmentBillingReport';
import { RecalculatePrices } from './RecalculatePrices';
import { canAccessPricing, isCarrierCoordinator } from '../../utils';
import { useOutletContext } from 'react-router-dom';
import { CarrierUser } from '../../reducers/authReducer';

const TAB_QUERY_PARAMETER = 'tab';

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface TabBarProps extends React.HTMLAttributes<HTMLElement> {
  tab: number; // <Tabs> expects a number
  setTab: (tab: number) => void;
  hasCoordinatorAccess?: boolean;
}

const TabBar: React.FC<TabBarProps> = ({ tab, setTab, hasCoordinatorAccess }) => {
  return (
    <Paper square>
      <Tabs
        value={tab}
        onChange={(_event, newValue) => setTab(newValue)}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        textColor="primary"
        aria-label="reports-tabs"
        style={{ marginBottom: '1rem' }}
        allowScrollButtonsMobile
      >
        <Tab className="shipment-report-tab" label="Toimitusraportti" {...a11yProps(0)} />
        {hasCoordinatorAccess ? (
          <Tab className="shipment-aggregaters-report-tab" label="Asiakasraportti" {...a11yProps(1)} />
        ) : null}
        {hasCoordinatorAccess ? (
          <Tab className="shipment-billing-report-tab" label="Laskutusraportti" {...a11yProps(2)} />
        ) : null}
        {hasCoordinatorAccess ? (
          <Tab className="recalculate-prices-tab" label="Hintojen laskenta" {...a11yProps(3)} />
        ) : null}
      </Tabs>
    </Paper>
  );
};

const ReportsMain = styled(Main)({
  padding: 0,
});

const Content = styled('div')({
  padding: '0 1rem 1rem 1rem',
});

export const Reports: React.FC = () => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [tab, setTab] = useState<number>(0);
  const hasCoordinatorAccess = isCarrierCoordinator(carrierUser);
  const hasPricingAccess = canAccessPricing(carrierUser);
  const tabsOfCurrentUser =
    hasCoordinatorAccess && hasPricingAccess
      ? [0, 1, 2, 3]
      : hasCoordinatorAccess
        ? [0, 1, 2]
        : hasPricingAccess
          ? [0, 3]
          : [0];

  function changeTab(newTab: number) {
    const url = new URL(window.location.href);
    url.searchParams.set(TAB_QUERY_PARAMETER, String(newTab));
    window.history.replaceState(null, window.document.title, url.href);
    setTab(newTab);
  }
  useEffect(() => {
    const url = new URL(window.location.href);
    const urlTab = Number(url.searchParams.get(TAB_QUERY_PARAMETER)) ?? 0;
    if (carrierUser) {
      changeTab(tabsOfCurrentUser.indexOf(urlTab) !== -1 ? urlTab : 0);
    }
  }, [carrierUser, hasCoordinatorAccess, hasPricingAccess]);

  return (
    <ReportsMain>
      <TabBar tab={tab} setTab={changeTab} hasCoordinatorAccess={hasCoordinatorAccess} />
      <Content>
        {tab === 0 ? <ShipmentReport state={state} dispatch={dispatch} /> : null}
        {tab === 1 && hasCoordinatorAccess ? <ShipmentAggregatesReport state={state} dispatch={dispatch} /> : null}
        {tab === 2 && hasCoordinatorAccess ? <ShipmentBillingReport state={state} dispatch={dispatch} /> : null}
        {tab === 3 && hasCoordinatorAccess ? <RecalculatePrices state={state} dispatch={dispatch} /> : null}
      </Content>
    </ReportsMain>
  );
};
