import { Add, Clear, Done } from '@mui/icons-material';
import { Tooltip, IconButton, Link } from '@mui/material';
import { DateTime } from 'luxon';
import { createMRTColumnHelper, MRT_TableOptions } from 'material-react-table';
import React, { FunctionComponent, useReducer, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useOutletContext } from 'react-router-dom';
import MRT from '../../components/MaterialReactTable/MRT';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { dateFormat } from '../../formatters';
import { CarWithLastService, initialState, load, reducer } from './cars.state';
import { CarrierUser } from '../../reducers/authReducer';

const VIEW_ID = 'cars' as const;

const getFormattedDateIfExist = (dateTime: DateTime | null) => (dateTime ? dateTime.toFormat(dateFormat) : dateTime);
const getIsActiveIcon = (isActive: string) =>
  isActive === 'Kyllä' ? (
    <Done color="success" titleAccess={isActive} />
  ) : (
    <Clear color="error" titleAccess={isActive} />
  );

const Cars: FunctionComponent = () => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    if (carrierUser) {
      load(carrierUser, dispatch);
    }
  }, [carrierUser]);

  const data = React.useMemo(() => state.cars, [state.cars]);

  const columnHelper = createMRTColumnHelper<CarWithLastService>();

  const filterOptions = ['Kyllä', 'Ei'];

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('licence_plate', {
        header: 'Rekisterinumero',
        Cell: ({ cell, renderedCellValue }) => (
          <Link component={RouterLink} to={{ pathname: `/cars/${cell.getValue()}` }}>
            {renderedCellValue}
          </Link>
        ),
        filterFn: 'includesString',
      }),

      columnHelper.accessor('car_number', {
        header: 'Numero',
      }),
      columnHelper.accessor('manufacturer', {
        header: 'Merkki',
      }),
      columnHelper.accessor('model', {
        header: 'Malli',
      }),
      columnHelper.accessor('type', {
        header: 'Tyyppi',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
      }),
      columnHelper.accessor('unladen_weight', {
        header: 'Omapaino',
      }),
      columnHelper.accessor('gross_weight', {
        header: 'Kokonaispaino',
      }),
      columnHelper.accessor('height', {
        header: 'Korkeus',
      }),
      columnHelper.accessor('car_identifier', {
        header: 'Tunnus',
      }),
      columnHelper.accessor(
        (row) =>
          row.vehicle_inspection_date ? DateTime.fromJSDate(row.vehicle_inspection_date) : row.vehicle_inspection_date,
        {
          id: 'vehicle_inspection_date',
          header: 'Katsastettu',
          filterFn: 'dateFilterFn',
          filterVariant: 'date',
          Cell: ({ cell }) => getFormattedDateIfExist(cell.getValue()),
        },
      ),
      columnHelper.accessor(
        (row) =>
          row.hoist_inspection_date ? DateTime.fromJSDate(row.hoist_inspection_date) : row.hoist_inspection_date,
        {
          id: 'hoist_inspection_date',
          header: 'Nosturi katsastettu',
          filterFn: 'dateFilterFn',
          filterVariant: 'date',
          Cell: ({ cell }) => getFormattedDateIfExist(cell.getValue()),
        },
      ),
      columnHelper.accessor((row) => (row.lastService ? DateTime.fromJSDate(row.lastService) : row.lastService), {
        id: 'lastService',
        header: 'Edellinen huolto',
        filterFn: 'dateFilterFn',
        filterVariant: 'date',
        Cell: ({ cell }) => getFormattedDateIfExist(cell.getValue()),
      }),
      columnHelper.accessor('hoist', {
        header: 'Nosturi',
      }),
      columnHelper.accessor((row) => (row.is_coordination ? 'Kyllä' : 'Ei'), {
        id: 'is_coordination',
        header: 'Ajojärjestely',
        filterVariant: 'select',
        filterSelectOptions: filterOptions,
        Cell: ({ cell }) => getIsActiveIcon(cell.getValue()),
      }),
      columnHelper.accessor((row) => (row.is_active ? 'Kyllä' : 'Ei'), {
        id: 'is_active',
        header: 'Aktiivinen',
        filterVariant: 'select',
        filterSelectOptions: filterOptions,
        Cell: ({ cell }) => getIsActiveIcon(cell.getValue()),
      }),
    ],
    [],
  );

  const tableOptions: MRT_TableOptions<CarWithLastService> = {
    data: data,
    columns: columns,
    initialState: {
      sorting: [{ id: 'licence_plate', desc: false }],
      columnFilters: [{ id: 'is_active', value: 'Kyllä' }],
    },
  };

  return (
    <>
      <MRT
        header={'Autot'}
        viewId={VIEW_ID}
        isLoading={state.isLoading}
        customActions={() => (
          <Tooltip title="Luo uusi auto">
            <span>
              <IconButton
                data-cy="create-new-car-button"
                disabled={state.isLoading}
                onClick={() => {
                  navigate('/cars/new');
                }}
              >
                <Add color="success" />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {...tableOptions}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default Cars;
