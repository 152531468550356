import React from 'react';
import { DateTimePicker, DateTimePickerProps, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { dateTimeFormat } from '../../formatters';
import { convertToJsDateWithZeroSecondsOrNull, TimeClockView } from './StandardTimePicker';
import { mobileActionBar } from './StandardDatePicker';

export const StandardDateTimePicker: React.FC<DateTimePickerProps<any> & { isMobile?: boolean }> = (props) => {
  const Picker = props.isMobile ? DateTimePicker : DesktopDateTimePicker;
  return (
    <Picker
      {...props}
      format={dateTimeFormat}
      value={props.value instanceof Date ? DateTime.fromJSDate(props.value) : props.value}
      viewRenderers={{ hours: TimeClockView, minutes: TimeClockView }}
      slotProps={{
        actionBar: mobileActionBar,
        textField: {
          ...props.slotProps?.textField,
          size: 'small',
          variant: 'standard',
          error: (props.slotProps?.textField as any).error,
          helperText: (props.slotProps?.textField as any).helperText,
          inputProps: {
            placeholder: '',
          },
        },
        tabs: {
          hidden: false,
        },
      }}
      onChange={(time, context) => {
        const formattedTime = convertToJsDateWithZeroSecondsOrNull(time);
        props.onChange && props.onChange(formattedTime, context);
      }}
    />
  );
};
