import { createMRTColumnHelper, DropdownOption, MRT_TableOptions } from 'material-react-table';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { ShipmentRow, ShipmentRowStateEnum, User } from '../../../api';
import MRTEditable, { IsNewAndIsUpdated } from '../../../components/MaterialReactTable/MRTEditableTable';
import { formatShipmentRowState } from '../../../formatters';
import {
  typeRequired,
  parcelId,
  quantity,
  quantityForCoordinators,
  serialNumber,
  shipmentRowName,
  volumeM3,
  weightKg,
  weightKgForCoordinators,
} from '../../../validation';
import { MRT_EditCellTextField } from '../../../components/MaterialReactTable/MRTEditTextField';
import { MRT_EditNumberInput } from '../../../components/MaterialReactTable/MRTEditNumberInput';
import { IconButton, Tooltip } from '@mui/material';
import { ListAlt } from '@mui/icons-material';
import { canAccessCoordination, isCarrierCoordinator } from '../../../utils';
import { ShipmentRowProductsModal } from '../../../components/ShipmentRowProductsModal';
import { orderBy } from 'lodash';

export type ShipmentRowWithIsNewAndUpdated = ShipmentRow & IsNewAndIsUpdated;

type NewShipmenRowTableProps = {
  shipmentRows: ShipmentRowWithIsNewAndUpdated[];
  setShipmentRows: Dispatch<ShipmentRowWithIsNewAndUpdated[]>;
  setAreShipmentRowsBeingEdited: Dispatch<SetStateAction<boolean>>;
  currentUser: User | undefined;
  isEditShipment?: boolean;
  canEdit?: boolean;
};

type FieldName = keyof Pick<
  ShipmentRow,
  'serial_number' | 'parcel_id' | 'name' | 'quantity' | 'volume_m3' | 'weight_per_piece_kg' | 'state'
>;

const NewShipmenRowTable: React.FC<NewShipmenRowTableProps> = ({
  shipmentRows,
  setShipmentRows,
  setAreShipmentRowsBeingEdited,
  currentUser,
  isEditShipment,
  canEdit,
}) => {
  const coordinationAccess = canAccessCoordination(currentUser) || isCarrierCoordinator(currentUser);
  const [modalShipmentRow, setModalShipmentRow] = useState<ShipmentRow | undefined>(undefined);

  const validateData = (fieldName: FieldName, value: string | number | null) => {
    switch (fieldName) {
      case 'serial_number': {
        return serialNumber.validate(value);
      }
      case 'parcel_id': {
        return parcelId.validate(value);
      }
      case 'name': {
        return shipmentRowName.validate(value);
      }
      case 'quantity': {
        const quantityValidation = coordinationAccess ? quantityForCoordinators : quantity;
        return quantityValidation.validate(value);
      }
      case 'volume_m3': {
        return volumeM3.validate(value);
      }
      case 'weight_per_piece_kg': {
        const weightValidation = coordinationAccess ? weightKgForCoordinators : weightKg;
        return weightValidation.validate(value);
      }
      case 'state': {
        return typeRequired.validate(value);
      }
    }
  };

  const stateList: DropdownOption[] = Object.values(ShipmentRowStateEnum).map((value) => ({
    label: formatShipmentRowState(value),
    value: value,
  }));

  const columnHelper = createMRTColumnHelper<ShipmentRowWithIsNewAndUpdated>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('serial_number', {
        header: 'Sarjanumero',
        Edit: ({ cell, table }) => <MRT_EditCellTextField cell={cell} table={table} />,
      }),
      columnHelper.accessor('parcel_id', {
        header: 'Tunnus',
        Edit: ({ cell, table }) => <MRT_EditCellTextField cell={cell} table={table} />,
      }),
      columnHelper.accessor('name', {
        header: 'Nimi',
        Edit: ({ cell, table }) => <MRT_EditCellTextField cell={cell} table={table} />,
      }),
      columnHelper.accessor('quantity', {
        header: 'Kpl',
        Edit: ({ cell, table }) => <MRT_EditNumberInput cell={cell} table={table} />,
        size: 60,
      }),
      columnHelper.accessor('weight_per_piece_kg', {
        header: 'À paino (kg)',
        Edit: ({ cell, table }) => <MRT_EditNumberInput cell={cell} table={table} />,
        size: 60,
      }),
      columnHelper.accessor('total_weight_kg', {
        header: 'Paino yhteensä (kg)',
        enableEditing: false,
        size: 60,
      }),
      columnHelper.accessor('volume_m3', {
        header: 'Tilavuus (m³)',
        Edit: ({ cell, table }) => <MRT_EditNumberInput cell={cell} table={table} />,
        size: 60,
      }),
      columnHelper.accessor('state', {
        id: 'state',
        header: 'Tila',
        editVariant: 'select',
        editSelectOptions: stateList,
        Edit: ({ cell, table }) => <MRT_EditCellTextField cell={cell} table={table} />,
        Cell: ({ cell }) => formatShipmentRowState(cell.getValue<string>()),
        enableEditing: coordinationAccess && isEditShipment ? true : false,
      }),
    ],
    [coordinationAccess],
  );

  const handleCreateUpdateDelete = (shipmentRows: ShipmentRowWithIsNewAndUpdated[]) =>
    setShipmentRows(orderBy(shipmentRows, ['isNew', 'id'], ['desc', 'asc']));

  const tableOptions: MRT_TableOptions<ShipmentRowWithIsNewAndUpdated> = {
    data: shipmentRows,
    columns: columns,
    enableSorting: false,
    positionCreatingRow: 'bottom',
    enableDensityToggle: false,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  };

  const allowShipmentRowEdit = canEdit || !isEditShipment;

  const editOptions = {
    ...(allowShipmentRowEdit && { handleCreate: handleCreateUpdateDelete }),
    ...(allowShipmentRowEdit && { handleSave: handleCreateUpdateDelete }),
    ...(allowShipmentRowEdit && { handleDelete: handleCreateUpdateDelete }),
  };

  return (
    <>
      <MRTEditable
        header={'Tuotteet'}
        validateData={validateData}
        setIsEditing={setAreShipmentRowsBeingEdited}
        createRowDefaultValues={{ state: ShipmentRowStateEnum.ReadyForPickup }}
        rowSelection={coordinationAccess}
        customRowActions={(row) => (
          <Tooltip
            className="show-shipment-row-products-button-tooltip"
            placement="bottom"
            title={'Näytä lähetysluettelo'}
            aria-label={'Näytä lähetysluettelo'}
          >
            <span>
              <IconButton
                disabled={false}
                className="show-shipment-row-products-button"
                onClick={() => setModalShipmentRow(row.original)}
              >
                <ListAlt />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {...editOptions}
        {...tableOptions}
      />
      {modalShipmentRow && (
        <ShipmentRowProductsModal
          shipmentRow={modalShipmentRow}
          open={modalShipmentRow !== undefined}
          onClose={() => setModalShipmentRow(undefined)}
        />
      )}
    </>
  );
};

export default NewShipmenRowTable;
