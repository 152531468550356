import React, { Dispatch, ReactNode, useEffect, useMemo, useState } from 'react';
import { IconButton, TableCellProps, Tooltip, Typography } from '@mui/material';
import { MRTDateRangePickerProps } from './MRTDateRangePicker';
import { MRT_Localization_FI } from 'material-react-table/locales/fi';
import {
  MaterialReactTable,
  MRT_TableState,
  MRT_PaginationState,
  MRT_TableOptions,
  MRT_TableInstance,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_Cell,
  MRT_Column,
  MRT_Row,
} from 'material-react-table';
import { getViewSettings, updateViewSettings } from '../../views/settings';
import { MRTToolbar } from './MRTToolbar';
import { DateTime } from 'luxon';
import { dateFormat } from '../../formatters';
import { MRT_EditActionButtons } from './MRTEditActionButtons';
import { Cancel, Delete, Edit } from '@mui/icons-material';
import { Loading } from '../Loading';
import theme from '../../theme';
import { DateRange, getDateRangeFromUrl, getTodaysDateRange, updateDateRangeToUrl } from '../../utils/dateRangeUtils';
import { isEven } from '../../utils';

export const customDateTime = (rowA: any, rowB: any, columnId: string) => {
  const minJSDateInMilliseconds = -8640000000000000;
  const row1Value = rowA.getValue(columnId)
    ? DateTime.fromFormat(rowA.getValue(columnId), dateFormat).toJSDate().getTime()
    : minJSDateInMilliseconds;
  const row2Value = rowB.getValue(columnId)
    ? DateTime.fromFormat(rowB.getValue(columnId), dateFormat).toJSDate().getTime()
    : minJSDateInMilliseconds;
  return row1Value - row2Value;
};

interface CellTooltipProps<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>;
  column: MRT_Column<TData>;
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
}

export const getTooltipFromTable = <TData extends MRT_RowData>({
  cell,
  column,
  row,
  table,
}: CellTooltipProps<TData>): TableCellProps => {
  const tooltip = cell.getValue<string>() ?? '';
  const density = table.getState().density;
  return {
    style: {
      //detail panel is easier to read
      border: getBorderStyleFromRow(row),
      //grouped rows are easier to read
      fontWeight: row.getIsGrouped() ? 'bold' : undefined,
    },
    //show tooltip on compact density with long fields
    ...(tooltip.length * 5 > column.getSize() &&
      density === 'compact' && {
        title: tooltip,
      }),
  };
};

const getBorderStyleFromRow = <TData extends MRT_RowData>(row: MRT_Row<TData>) =>
  row.getIsExpanded() && !row.getIsGrouped() ? 'none' : undefined;

export interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  header: string;
  viewId: string;
  isLoading: boolean;
  customActions?: (props: { table: MRT_TableInstance<TData> }) => ReactNode;
  extraViewSettings?: Record<string, any>;
  dispatch?: Dispatch<any>;
  isValid?: boolean;
  handleDeleteRow?: (id: number) => void;
  initialDateRange?: DateRange | null;
  salaryPeriodDateRange?: boolean;
  billingSeasonRange?: boolean;
}

export const MRT = <TData extends MRT_RowData>({
  columns,
  data,
  header,
  viewId,
  isLoading,
  customActions,
  dispatch,
  extraViewSettings,
  isValid,
  handleDeleteRow,
  initialDateRange,
  salaryPeriodDateRange,
  billingSeasonRange,
  ...rest
}: Props<TData>) => {
  const dateRange = getDateRangeFromUrl() ?? initialDateRange ?? getTodaysDateRange();

  const viewSettings = useMemo(() => getViewSettings(viewId), []);

  const [showColumnFilters, setShowColumnFilters] = useState<MRT_TableState<TData>['showColumnFilters']>(
    viewSettings.showColumnFilters ?? rest.initialState?.showColumnFilters ?? true,
  );
  const [columnFilters, setColumnFilters] = useState<MRT_TableState<TData>['columnFilters']>(
    viewSettings.columnFilters ?? rest.initialState?.columnFilters ?? [],
  );
  const [sorting, setSorting] = useState<MRT_TableState<TData>['sorting']>(
    viewSettings.sorting ?? rest.initialState?.sorting ?? [],
  );
  const [density, setDensity] = useState<MRT_TableState<TData>['density']>(
    viewSettings.density ?? rest.initialState?.density ?? 'compact',
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_TableState<TData>['columnVisibility']>(
    viewSettings.columnVisibility ?? rest.initialState?.columnVisibility ?? {},
  );
  const [columnSizing, setColumnSizing] = useState<MRT_TableState<TData>['columnSizing']>(
    viewSettings.columnSizing ?? rest.initialState?.columnSizing ?? {},
  );
  const [columnOrder, setColumnOrder] = useState<MRT_TableState<TData>['columnOrder']>(
    viewSettings.columnOrder ?? rest.initialState?.columnOrder ?? [],
  );
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_TableState<TData>['columnFilterFns']>(
    viewSettings.columnFilterFns ?? rest.initialState?.showColumnFilters ?? {},
  );
  const [columnPinning, setColumnPinning] = useState<MRT_TableState<TData>['columnPinning']>(
    viewSettings.columnPinning ?? rest.initialState?.columnFilterFns ?? {},
  );
  const [grouping, setGrouping] = useState<MRT_TableState<TData>['grouping']>(
    viewSettings.grouping ?? rest.initialState?.grouping ?? [],
  );
  const [expanded, setExpanded] = useState<MRT_TableState<TData>['expanded']>(
    viewSettings.expanded ?? rest.initialState?.expanded ?? { expanded: false },
  );
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    viewSettings.pagination ??
      rest.initialState?.pagination ?? {
        pageIndex: 0,
        pageSize: 50,
      },
  );
  const [deletingRow, setDeletingRow] = useState<number | null>(null);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableFilters: true,
    enableColumnActions: false,
    enableGrouping: true,
    enableHiding: true,
    enableColumnPinning: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    enableBottomToolbar: true,
    layoutMode: 'grid',
    columnResizeMode: 'onEnd',
    positionToolbarAlertBanner: 'bottom',
    localization: { ...MRT_Localization_FI, filterArrIncludesSome: 'Sisältää' },
    renderToolbarInternalActions: ({ table }) => (
      <MRTToolbar
        table={table}
        dateRangePickerProps={dateRangePickerProps}
        dateRange={dateRange}
        header={header}
        viewId={viewId}
        isLoading={isLoading}
        showDateRange={Boolean(dispatch)}
      />
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div
        data-cy="mrt-header"
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          alignSelf: 'center',
        }}
      >
        <div style={{ justifySelf: 'start' }}>
          <Typography variant="h3">
            {header}{' '}
            <strong style={{ color: theme.palette.primary.dark }}>{table.getFilteredRowModel().rows.length}</strong>
          </Typography>
        </div>
        <div style={{ justifySelf: 'end', alignItems: 'top' }}>{customActions && customActions({ table })}</div>
      </div>
    ),
    onDensityChange: setDensity,
    onShowColumnFiltersChange: setShowColumnFilters,
    onSortingChange: setSorting,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    state: {
      density,
      showColumnFilters,
      columnFilterFns,
      columnFilters,
      columnPinning,
      sorting,
      columnSizing,
      columnOrder,
      columnVisibility,
      grouping,
      pagination,
      expanded,
    },
    sortingFns: { customDateTime: customDateTime },
    filterFns: {
      dateFilterFn: (row, id, filterValue) => {
        return row.getValue<DateTime>(id).startOf('day').equals(DateTime.fromISO(filterValue));
      },
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        muiTableBodyCellProps: ({ row }) => ({
          style: {
            //detail panel is easier to read
            border: getBorderStyleFromRow(row),
          },
        }),
      },
      'mrt-row-actions': {
        size: 120,
        Cell: ({ row, table }) => {
          return row.original.enableEditing === false ? (
            <></>
          ) : table.getState().editingRow?.id === row.id ? (
            <MRT_EditActionButtons row={row} table={table} isValid={isValid ?? true} isLoading={isLoading} />
          ) : deletingRow !== row.original.id ? (
            <>
              <IconButton
                onClick={() => {
                  table.setEditingRow(row);
                  setDeletingRow(null);
                }}
                disabled={isLoading}
                data-cy="edit-row-button"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => setDeletingRow(row.original.id)}
                disabled={isLoading}
                data-cy="delete-row-button"
              >
                <Delete color="error" />
              </IconButton>
            </>
          ) : (
            <div>
              <Tooltip arrow title={'Poista'}>
                <IconButton
                  onClick={() => handleDeleteRow && handleDeleteRow(row.original.id)}
                  disabled={isLoading}
                  data-cy="confirm-delete-row-button"
                >
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={'Peruuta'}>
                <IconButton
                  onClick={() => setDeletingRow(null)}
                  disabled={isLoading}
                  data-cy="cancel-delete-row-button"
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
    defaultColumn: {
      size: 100,
      muiColumnActionsButtonProps: {
        style: { padding: 0 },
      },
      muiFilterDatePickerProps: ({ column }) =>
        ({
          slotProps: {
            textField: {
              inputProps: {
                placeholder: '',
              },
              sx: {
                // always show date picker icon
                flexDirection: 'row-reverse',
              },
            },
          },
          value: column.getFilterValue() ? DateTime.fromISO(column.getFilterValue() as string) : null,
        }) as any,
      muiFilterTextFieldProps: {
        //filters take less space
        size: 'small',
        placeholder: '',
        margin: 'none',
        helperText: '',
      },
      muiTableBodyCellProps: ({ table, row, cell, column }) => getTooltipFromTable({ table, row, cell, column }),
      muiTableHeadCellProps: ({ column }) => ({
        sx: {
          whiteSpace: 'nowrap',
          //long headers wont spread into multiple rows
          ' .css-pj3kdi': {
            whiteSpace: 'nowrap',
          },
          //only show sort icons when sorted
          ' .MuiTableSortLabel-iconDirectionAsc': {
            display: column.getIsSorted() ? undefined : 'none',
          },
          // only show drag handles on hover
          ' .Mui-TableHeadCell-Content-Actions': {
            display: 'none',
            '> button': {
              padding: 0,
            },
          },
          '&:hover': {
            ' .Mui-TableHeadCell-Content-Actions': {
              display: 'contents',
            },
          },
        },
      }),
    },
    muiTableBodyRowProps: ({ staticRowIndex, row }) => ({
      sx: {
        //detail panel is easier to read
        border: getBorderStyleFromRow(row),
        //grouped rows are easier to read
        fontWeight: row.getIsGrouped() ? 'bold' : undefined,
        //striped table rows
        backgroundColor: isEven(staticRowIndex) ? undefined : theme.palette.table.light,
      },
    }),
    muiDetailPanelProps: ({ row }) => ({
      style: {
        //striped detail panel  rows
        backgroundColor: isEven(row.index) ? undefined : theme.palette.table.light,
      },
    }),
    muiTableHeadProps: {
      style: {
        opacity: 1,
        width: '100%',
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
      menuBackgroundColor: theme.palette.common.white,
      pinnedRowBackgroundColor: theme.palette.common.white,
      selectedRowBackgroundColor: theme.palette.common.white,
    }),
    ...rest,
  });

  useEffect(() => {
    updateViewSettings(viewId, {
      ...viewSettings,
      showColumnFilters,
      columnFilters,
      sorting,
      columnVisibility,
      columnSizing,
      density,
      columnOrder,
      grouping,
      columnFilterFns,
      columnPinning,
      pagination,
      ...extraViewSettings,
    });
  }, [
    showColumnFilters,
    columnFilters,
    sorting,
    columnVisibility,
    columnSizing,
    density,
    columnOrder,
    grouping,
    columnFilterFns,
    columnPinning,
    pagination,
    extraViewSettings,
  ]);

  const dateRangePickerProps: MRTDateRangePickerProps = {
    startDate: dateRange?.start ?? null,
    endDate: dateRange?.end ?? null,
    disabled: isLoading,
    onValue: (startDate, endDate): void => {
      const newDateRange = {
        start: startDate,
        end: endDate,
      };
      if (dispatch) {
        dispatch({
          type: 'SET_DATE_RANGE',
          payload: newDateRange,
        });
      }
      updateDateRangeToUrl(newDateRange);
    },
    salaryPeriodDateRange: salaryPeriodDateRange,
    billingSeasonRange: billingSeasonRange,
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <MaterialReactTable table={table} />
    </>
  );
};

export default MRT;
