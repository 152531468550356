import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Driver, CarrierLoad, Organization } from '../api';

export interface LoadPickerProps {
  className?: string;
  loads: CarrierLoad[];
  drivers: Driver[];
  organizations: Organization[];
  value: CarrierLoad | null;
  onChange: (load: CarrierLoad | null) => void;
  disabled?: boolean;
}

export const LoadPicker: React.FC<LoadPickerProps> = ({
  className,
  disabled,
  loads,
  drivers,
  organizations,
  value,
  onChange,
}) => {
  const driverNames = drivers.map((driver) => ({
    name: `${driver.last_name} ${driver.first_name}`,
    id: driver.id,
  }));
  const loadAndDriver = (load: CarrierLoad) => {
    return `${organizations.find((organization) => organization.id === load.organization_id)?.name} (${load.id}) - ${
      driverNames.find((driver) => driver.id === load.driver_id)?.name ?? 'Ei kuljettajaa'
    }`;
  };
  return (
    <Autocomplete
      sx={{ width: '14rem' }}
      disabled={disabled}
      className={'load-picker ' + className}
      options={loads.sort((load1, load2) =>
        loadAndDriver(load1).toLowerCase() > loadAndDriver(load2).toLowerCase() ? 1 : -1,
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      autoHighlight
      defaultValue={null}
      getOptionLabel={(load) => loadAndDriver(load)}
      renderOption={(props, load) => <li {...props}>{loadAndDriver(load)}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Kuorma"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={value}
      onChange={(_event, load) => {
        onChange(load);
      }}
    />
  );
};
